import './App.css';
import { BrowserRouter , Route, Switch, Routes } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home  from './Pages/Home';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Products from './Pages/Products';
import ProductDetail from './Pages/ProductDetail';
import Wishlist from './Pages/Wishlist';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Myaccount from './Pages/Myaccount';
import OrderHistory from './Pages/OrderHistory';
import OrderConfirmation from './Pages/OrderConfirmation';
import Return from './Pages/Return';
import Contact from './Components/Contact';
import Aboutus from './Components/Aboutus';
import Shop from './Pages/Shop';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';

function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/products/:c_id' element={<Products/>}/>
    <Route path='/productdetail/:product_id' element={<ProductDetail/>}/>
    <Route path='/cart' element={<Cart/>}/>
    <Route path='/shop' element={<Shop/>}/>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
    <Route path='/terms' element={<Terms/>}/>

    <Route path='/checkout' element={<Checkout/>}/>
    <Route path='/wishlist' element={<Wishlist/>}/>
    <Route path='/register' element={<Register/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/myaccount' element={<Myaccount/>}/>
    <Route path='/orderhistory' element={<OrderHistory/>}/>
    <Route path='/orderconfirmation/:order_id' element={<OrderConfirmation/>}/>
    <Route path='/return' element={<Return/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/aboutus' element={<Aboutus/>}/>



     </Routes>
    
    </BrowserRouter>
  );
}

export default App;
