import React, { useState, useEffect, useContext } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CartContext } from "../Context/CartContext";
import LeftImage from "../Assets/left-image-static.png";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import { Offcanvas, Button } from "react-bootstrap";
import { BiSliderAlt } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaShoppingCart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";

const Products = () => {
  const { c_id } = useParams();
  const [openIndex, setOpenIndex] = useState(null);
  const [viewMode, setViewMode] = useState("grid");
  const [products, setProducts] = useState([]);
  const [latest, setLatest] = useState([]);
  const [categories, setCategories] = useState([]);
  const { setCartItems } = useContext(CartContext);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [wishlistmessage, setWishListMessage] = useState("");
  const [title, setTitle] = useState([]);
  const [subtitle, setSubTitle] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [message, setMessage] = useState("");
  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
  const [loading, setLoading] = useState(true);

  const handleOffcanvasToggle = () => {
    setShowOffcanvas(!showOffcanvas);
  };
  // Sorting function
  const sortProducts = (products, sortBy) => {
    switch (sortBy) {
      case "name-asc":
        return products.sort((a, b) =>
          a.product_name.localeCompare(b.product_name)
        );
      case "name-desc":
        return products.sort((a, b) =>
          b.product_name.localeCompare(a.product_name)
        );
      case "price-asc":
        return products.sort((a, b) => a.online_rate - b.online_rate);
      case "price-desc":
        return products.sort((a, b) => b.online_rate - a.online_rate);
      default:
        return products;
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get current products for the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;  

  // Remove this line as `currentProducts` is already declared as a state variable
  // const currentProducts = sortProducts(products, sortBy).slice(indexOfFirstProduct, indexOfLastProduct);

  // Use setCurrentProducts to manage pagination or filtering
  useEffect(() => {
    const paginatedProducts = sortProducts(products, sortBy).slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );
    setCurrentProducts(paginatedProducts); // Update the state
  }, [currentPage, products, sortBy]);
  // Calculate total pages
  const totalPages = Math.ceil(products.length / productsPerPage);

  const toggleCategories = () => {
    setShowAllCategories((prevState) => !prevState); // Toggle the state
  };

  useEffect(() => {
    setLoading(true); // Start loading
  
    fetch("https://unitedmart.in/customerapp/product.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ c_id }), // Send selected c_id to fetch relevant products
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.head.code === 200) {
          setProducts(data.body.products);
          console.log("categoryproducts", data.body.products);
          setLatest(data.body.latest);
          setTitle(data.body.top_title[0]?.main_category_name || "");
          setSubTitle(data.body.top_title[0]?.sub_category_name || "");
          setCategories(data.body.side_category);
        } else {
          console.error("Error fetching products:", data.head.msg);
        }
      })
      .catch((error) => console.error("Error fetching API:", error))
      .finally(() => {
        setLoading(false); // Stop loading AFTER data is fetched
      });
  }, [c_id]);
  

  // const handleCategoryClick = (categoryId) => {
  //   if (!categoryId) {
  //     console.error("Category ID is undefined or null");
  //     return;
  //   }
  
  //   console.log("Clicked category ID:", categoryId);
  //   console.log("Products array:", products);
  
  //   const filteredProducts = products.filter(
  //     (product) => product.category_id === categoryId
  //   );
  
  //   setCurrentProducts(filteredProducts);
  //   console.log("Filtered Products:", filteredProducts);
  // };
  
  // Toggle the dropdown
  const toggleMenu = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const addToCart = (product) => {
    setCartItems((prevCartItems) => {
      const existingProduct = prevCartItems.find(
        (item) => item.product_id === product.product_id
      );

      if (existingProduct) {
        // If product already exists, increase its quantity
        return prevCartItems.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      // If product does not exist, add it with a default quantity of 1
      return [
        ...prevCartItems,
        {
          ...product,
          onlinerate: product.online_rate,
          qty:Number(product.qty),
          tax_amt: product.tax_amt,
          minimumquantity: Number(product.qty)
        },
      ];
    });
    setMessage("Item Added To Cart!");

    setTimeout(() => {
      setMessage("");
    }, 2500);
  };

  const handleAddToWishlist = (product) => {
    const userId = localStorage.getItem("user_id");
    console.log("USERID", localStorage.getItem("user_id"));
    if (!userId) {
      alert("Please log in to add items to your wishlist.");
      return;
    }

    const storedWishlist =
      JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
    const isAlreadyInWishlist = storedWishlist.some(
      (item) => item.product_id === product.product_id
    );

    if (!isAlreadyInWishlist) {
      const updatedWishlist = [...storedWishlist, product];
      localStorage.setItem(
        `wishlist_${userId}`,
        JSON.stringify(updatedWishlist)
      ); // Save to localStorage
      setWishlist(updatedWishlist); // Update state
      alert("Product added to wishlist!");
      setWishListMessage("Item Added To Wishlist");
      setTimeout(() => {
        setWishListMessage("");
      }, 2500);
    } else {
      alert("Product is already in your wishlist.");
    }
  };

  /* firstletter capital others  small */

  function capitalizeFirstLetter(str) {
    if (!str) return ""; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [range, setRange] = useState([0, 100000]); // Initial range for price filter
  const [currentProducts, setCurrentProducts] = useState(products); // Filtered product state

  const handleSliderChange = (value) => {
    setRange(value); // Update range when slider changes
  };

  const handleFilter = () => {
    const [minPrice, maxPrice] = range;

    const filteredProducts = products.filter((product) => {
      const productPrice = parseFloat(product.online_rate);
      return productPrice >= minPrice && productPrice <= maxPrice;
    });

    setCurrentProducts(filteredProducts);
  };

  return (
    <>
      <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
          <Header />
          {message && (
            <div className="message-popup">
              <FaShoppingCart className="cart-icon-addtocart" />
              <div className="message">{message}</div>
            </div>
          )}
          {wishlistmessage && (
            <div className="wishlist-message-popup">
              <FaHeart className="heart-icon-addtowishlist" />
              <div className="wishlist-message">{wishlistmessage}</div>
            </div>
          )}

          <div class="main-container container">
            <ul class="breadcrumb">
              <li>
                <Link to="/">
                  <i class="fa fa-home"></i>
                </Link>
              </li>
              <li>{title}</li>
              {/* <li>{subtitle}</li> */}
            </ul>

            <div class="row">
              <nobr>
                <span className="arrow " onClick={toggleOffcanvas}>
                  <h3 className="modtitle">Filters</h3> &nbsp;
                  <BiSliderAlt style={{ marginTop: "8px", fontSize: "16px" }} />
                </span>
              </nobr>

              <div className="product-container">
                {/* <!--Left Part Start --> */}
                <aside class="col-sm-4 col-md-3 aside" id="column-left">
                  <>
                   { <div className="module menu-category titleLine">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          width: "100%",
                        }}
                      >
                        <h3 className="modtitle modtitle-product">
                          Our Categories
                        </h3>
                      </div>

                      <div className="modcontent">
                        <div className="box-category">
                          <ul className="list-group">
                          {categories && categories.length > 0 ? ( // Show all categories
                                  categories.map((category) => {
                                    const CategoryName = category.category_name || " "; // Fallback for undefined
                                    return (
                                      <Link to={`/products/${category.category_id}`} reloadDocument>
                                      <ul className="list-group">
                                        <li
                                          key={category.category_id}
                                          className="hadchild"
                                          style={{ cursor: "pointer", color: "black" }}
                                        >
                                          {CategoryName}
                                        </li>
                                      </ul>
                                    </Link>
                                     
                                    );
                                  })
                                ) : (
                                  <div className="no-products">
                                    <p>No categories available</p>
                                  </div>
                                )}

                            {/* <li
                                  className="loadmore"
                                  onClick={toggleCategories}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="fa fa-plus-square-o"></i>
                                  <span
                                    className="more-view"
                                    style={{ cursor: "pointer", marginLeft: "5px" }}
                                  >
                                    {showAllCategories ? "Show Less" : "More Categories"}
                                  </span>
                                </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>}

                    {/* Price Range Section */}
                    <div
                      style={{
                        width: "200px",
                        margin: "20px auto",
                        textAlign: "center",
                      }}
                      className="price-filter"
                    >
                      <h4>Price Filter</h4>
                      <p>
                        Min: <i className="bi bi-currency-rupee"></i>
                        {range[0]} - Max:{" "}
                        <i className="bi bi-currency-rupee"></i>
                        {range[1]}
                      </p>
                      <Slider
                        range
                        min={0}
                        max={100000}
                        step={10}
                        defaultValue={[0, 100000]}
                        value={range}
                        onChange={handleSliderChange}
                        trackStyle={[{ backgroundColor: "#FF9900" }]}
                        handleStyle={[
                          { backgroundColor: "#FF9900", border: "none" },
                          { backgroundColor: "#FF9900", border: "none" },
                        ]}
                      />
                      <button
                        onClick={handleFilter}
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#FF9900",
                          color: "#fff",
                          border: "none",
                          padding: "7px 15px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Go
                      </button>
                    </div>
                  </>

                  {/* <div class="module latest-product titleLine">
                    <h3 class="modtitle">Latest Product</h3>
                    <div class="modcontent ">
                      {Array.isArray(latest) && latest.length > 0 ? (
                        latest.map((item) => (
                          <div class="product-latest-item">
                            <Link to={`/productdetail/${item.product_id}`}>
                              <div class="media">
                                <div class="media-left">
                                  <a href="#">
                                    <img
                                      src={item.image}
                                      alt="Cisi Chicken"
                                      title="Cisi Chicken"
                                      class="img-responsive"
                                      style={{
                                        width: " 100px",
                                        height: "82px",
                                      }}
                                    />
                                  </a>
                                </div>
                                <div class="media-body">
                                  <div class="caption">
                                    <h4>
                                      <a href="#">
                                        {capitalizeFirstLetter(
                                          item.product_name
                                        )}
                                      </a>
                                    </h4>

                                    <div class="price">
                                      <span class="price-new">
                                        <i class="bi bi-currency-rupee"></i>
                                        {item.online_rate}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))
                      ) : (
                        <p>""</p>
                      )}
                    </div>
                  </div> */}
                  {/* <div class="module">
                  <div class="modcontent clearfix">
                    <div class="banners">
                      <div>
                        <a href="#">
                          <img src={LeftImage} alt="left-image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
                </aside>
                {/* 	<!--Left Part End -->
			
			<!--Middle Part Start--> */}
                <div id="content" className="col-md-9 col-sm-8 main-content">
                  <div className="products-category">
                    <div className="product-filter filters-panel">
                      <div className="row">
                        <div className="col-md-2 visible-lg">
                          <div className="view-mode">
                            <button
                              className={`btn btn-default grid ${
                                viewMode === "grid" ? "active" : ""
                              }`}
                              onClick={() => setViewMode("grid")}
                            >
                              <i className="fa fa-th" />
                            </button>
                            <button
                              className={`btn btn-default list ${
                                viewMode === "list" ? "active" : ""
                              }`}
                              onClick={() => setViewMode("list")}
                            >
                              <i className="fa fa-th-list" />
                            </button>
                          </div>
                        </div>
                        <div className="short-by-show form-inline text-right col-md-7 col-sm-8 col-xs-12">
                          <div className="form-group short-by">
                            <label htmlFor="input-sort">Sort By:</label>
                            <select
                              id="input-sort"
                              className="form-control"
                              value={sortBy}
                              onChange={(e) => setSortBy(e.target.value)}
                            >
                              <option value="default">Default</option>
                              <option value="name-asc">Name (A - Z)</option>
                              <option value="name-desc">Name (Z - A)</option>
                              <option value="price-asc">
                                Price (Low &gt; High)
                              </option>
                              <option value="price-desc">
                                Price (High &gt; Low)
                              </option>
                            </select>
                          </div>
                          {/* <div className="form-group">
                          <label htmlFor="input-limit">Show:</label>
                          <select id="input-limit" className="form-control">
                            <option value="9">9</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                          </select>
                        </div> */}
                        </div>
                        <div className="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                          <ul className="pagination">
                            {Array.from({ length: totalPages }, (_, index) => (
                              <li
                                key={index + 1}
                                className={
                                  currentPage === index + 1 ? "active" : ""
                                }
                              >
                                <span
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </span>
                              </li>
                            ))}
                            <li>
                              <a
                                href="#"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                &gt;
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                onClick={() => handlePageChange(totalPages)}
                              >
                                &gt;|
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={`products-list row ${viewMode}`}>
  {/* Show nothing while loading */}
  {loading ? (
          <div className="loader-container">
          <div className="loader1"></div>
        </div>// Your custom loader here
      ) : currentProducts && currentProducts.length > 0 ? (
        <div className={`products-list row ${viewMode}`}>
          {currentProducts.map((product) => (
            <div key={product.id} className="product-layout col-md-4 col-sm-6 col-xs-12">
              <div className="product-item-container">
                <div className={`left-block ${viewMode === "list" ? "col-md-4" : ""}`}>
                  <Link to={`/productdetail/${product.product_id}`}>
                    <div className="product-image-container">
                      <img src={product.image} alt={product.product_name} className="img-responsive" />
                    </div>
                    {product.labels &&
                      product.labels.map((label, index) => (
                        <span key={index} className={`label label-${label.toLowerCase()}`}>
                          {product.discount}% OFF
                        </span>
                      ))}
                  </Link>
                </div>

                <div className={`right-block ${viewMode === "list" ? "col-md-8" : ""}`}>
                  <div className="caption">
                    <h4>
                      <p>{product.product_name}</p>
                    </h4>

                    <div className="responsive-mobile">
                      <div className="price">
                        <span className="price-new">
                          <i className="bi bi-currency-rupee"></i>
                          {parseFloat(product.online_rate).toFixed(2)}
                        </span>
                        {product.mrp_rate && product.mrp_rate > 0 && (
                          <span className="price-old">
                            <i className="bi bi-currency-rupee"></i>
                            {parseFloat(product.mrp_rate).toFixed(2)}
                          </span>
                        )}
                        &nbsp;
                        {product.discount > 0 && (
                          <span className="label label-percent">{product.discount}%</span>
                        )}
                      </div>

                      <div className={`description item-desc ${viewMode === "grid" ? "hidden" : ""}`}>
                        <p>{product.full_description}</p>
                      </div>

                      <div className="button-group">
                        <button
                          className="addToCart"
                          type="button"
                          title="Add to Cart"
                          onClick={() => addToCart(product)}
                        >
                          <i className="fa fa-shopping-cart" />
                          <span className="">Add to Cart</span>
                        </button>
                        <button
                          className="wishlist"
                          type="button"
                          title="Add to Wish List"
                          onClick={() => handleAddToWishlist(product)}
                        >
                          <i className="fa fa-heart" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No products available</div>
      )}
    </div>





                  </div>
                  {products.length > 9 && (
                    <div className="product-filter product-filter-bottom filters-panel">
                      <div className="row">
                        <div className="col-md-2 hidden-sm hidden-xs"></div>
                        <div className="short-by-show text-center col-md-7 col-sm-8 col-xs-12">
                          <div
                            className="form-group"
                            style={{ margin: "7px 10px" }}
                          >
                            Showing {indexOfFirstProduct + 1} to{" "}
                            {indexOfLastProduct} of {products.length} (Page{" "}
                            {currentPage} of {totalPages})
                          </div>
                        </div>
                        <div className="box-pagination col-md-3 col-sm-4 text-right">
                          <ul className="pagination">
                            <li className={currentPage === 1 ? "disabled" : ""}>
                              <span onClick={() => handlePageChange(1)}>
                                &lt;|
                              </span>
                            </li>
                            <li className={currentPage === 1 ? "disabled" : ""}>
                              <span
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                              >
                                &lt;
                              </span>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => (
                              <li
                                key={index + 1}
                                className={
                                  currentPage === index + 1 ? "active" : ""
                                }
                              >
                                <span
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </span>
                              </li>
                            ))}
                            <li
                              className={
                                currentPage === totalPages ? "disabled" : ""
                              }
                            >
                              <span
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                &gt;
                              </span>
                            </li>
                            <li
                              className={
                                currentPage === totalPages ? "disabled" : ""
                              }
                            >
                              <span
                                onClick={() => handlePageChange(totalPages)}
                              >
                                &gt;|
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <!--Middle Part End--> */}
          </div>
        </div>
      </div>
      <Footer />

      {/* Offcanvas for smaller screens */}
      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement="start"
      >
        <Offcanvas.Header>
          <Offcanvas.Title style={{fontWeight:"bold"}}>Our Categories</Offcanvas.Title>
          <Button
            variant="link"
            className="text-muted"
            style={{ fontSize: "24px", marginLeft: "auto" }}
            onClick={handleOffcanvasToggle}
          >
            <FontAwesomeIcon icon={faTimes} /> 
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="box-category">
          <ul className="list-group" style={{ listStyle: "none", color: "darkgrey", padding: 0 }}>
              {categories && categories.length > 0 ? (
                categories.map((category) => {
                  const brandName = category.category_name || "Unknown Brand"; // Fallback for undefined category name
                  return (
                    <Link to={`/products/${category.category_id}`} key={category.category_id} style={{ textDecoration: "none" }}>
                      <li
                        className="hadchild"
                        style={{
                          cursor: "pointer",
                          color: "#666",
                          padding: "10px 15px", // Add some padding to the list item
                          margin: "5px 0", // Space between items
                          borderRadius: "5px", // Rounded corners
                          transition: "background-color 0.3s, color 0.3s", // Smooth transition for hover effect
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0"; // Hover background color
                          e.target.style.color = "#333"; // Hover text color
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = ""; // Reset background on hover leave
                          e.target.style.color = "#666"; // Reset text color
                        }}
                      >
                        {brandName}
                      </li>
                    </Link>
                  );
                })
              ) : (
                <div className="no-products" style={{ padding: "10px", textAlign: "center" }}>
                  <p>No categories available</p>
                </div>
              )}

             {/*  <li
                className="loadmore"
                onClick={toggleCategories}
                style={{ fontWeight: "bold" }}
              >
                <i className="fa fa-plus-square-o"></i>
                <span
                  className="more-view"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  {showAllCategories ? "Show Less" : "More Categories"}
                </span>
              </li> */}
            </ul>
          </div>
          <div
            style={{ width: "200px", margin: "20px auto", textAlign: "center" }}
          >
            <h3>Price Filter</h3>
            <p>
              Min: <i className="bi bi-currency-rupee"></i>
              {range[0]} - Max: <i className="bi bi-currency-rupee"></i>
              {range[1]}
            </p>
            <Slider
              range
              min={0}
              max={100000}
              step={10}
              defaultValue={[0, 100000]}
              value={range}
              onChange={handleSliderChange}
              trackStyle={[{ backgroundColor: "#FF9900" }]}
              handleStyle={[
                { backgroundColor: "#FF9900", border: "none" },
                { backgroundColor: "#FF9900", border: "none" },
              ]}
            />
            <button
              onClick={handleFilter}
              style={{
                marginTop: "20px",
                backgroundColor: "#FF9900",
                color: "#fff",
                border: "none",
                padding: "10px 20px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Go
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Products;
