import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Loader from '../Components/Loader';
import { useNavigate } from 'react-router-dom';

const Myaccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const username = localStorage.getItem('name');

  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [addressId, setAddressId] = useState();
  const userId = localStorage.getItem('user_id');
	const navigate = useNavigate();

  
	useEffect(() => {
	  if (!userId) {
		navigate('/login'); // Redirect to login if user is not logged in
	  }
	}, [userId, navigate]);

  // Handle "Same as Billing" checkbox
  const handleCheckboxChange = () => {
    setSameAsBilling((prev) => !prev);
    if (!sameAsBilling) {
      setFormData((prevData) => ({
        ...prevData,
        shipping_name: prevData.name,
        shipping_address: prevData.address,
        shipping_city: prevData.city,
        shipping_pincode: prevData.pincode,
        shipping_state: prevData.state,
        shipping_landmark: prevData.landmark,
        shipping_phonenumber: prevData.phonenumber,
      }));
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    landmark: '',
    phonenumber: '',
    shipping_name: '',
    shipping_address: '',
    shipping_city: '',
    shipping_pincode: '',
    shipping_state: '',
    shipping_landmark: '',
    shipping_phonenumber: '',
  });

  

  const fetchAddress = async () => {
    console.log('address list user id', userId);

    try {
      const response = await fetch('https://unitedmart.in/customerapp/addresslist.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: userId }),
      });
      const result = await response.json();

      if (result.head.code === 200 && result.body.length > 0) {
        const addressData = result.body[0]; // Assuming the API returns an array of addresses
        setFormData({
          name: addressData.name,
          address: addressData.address,
          city: addressData.city,
          pincode: addressData.pincode,
          state: addressData.state,
          landmark: addressData.landmark,
          phonenumber: addressData.phonenumber,
          shipping_name: addressData.shipping_name,
          shipping_address: addressData.shipping_address,
          shipping_city: addressData.shipping_city,
          shipping_pincode: addressData.shipping_pincode,
          shipping_state: addressData.shipping_state,
          shipping_landmark: addressData.shipping_landmark,
          shipping_phonenumber: addressData.shipping_phonenumber,
        });
        setAddressId(addressData.address_id);
        console.log('address_id', addressData.address_id);
      } else {
        console.log('No address found.');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with the actual source of userId (e.g., localStorage, Context, Redux)
      const userId = localStorage.getItem('user_id'); // Example: Fetch from localStorage
      if (!userId) {
        alert('User ID is missing. Please log in again.');
        return;
      }

      // Add userId to the formData
      const dataToSend = { ...formData, user_id: userId, address_id: addressId }; // Ensure key matches API requirements
      console.log('add user id', userId);

      const response = await fetch(
        'https://unitedmart.in/customerapp/addresslistadd.php',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToSend),
        }
      );

      const result = await response.json();

      if (result.head.code === 200) {
        alert('Address saved successfully!');
        // Optionally, reset form or fetch updated data
		window.history.back(); 
      } else {
        alert(result.head.msg || 'Failed to save address');
      }
    } catch (error) {
      console.error('Error saving address:', error);
      alert('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    // Simulate a network request or loading process
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
          <div className="common-home res layout-home1">
            <div id="wrapper" className="wrapper-full banners-effect-7">
              <Header />
              <div className="main-container container">
                <ul className="breadcrumb">
                  <li>
                    <a href="#">
                      <i className="fa fa-home"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">Account</a>
                  </li>
                  <li>
                    <a href="#">My Account</a>
                  </li>
                </ul>

                <div className="row">
                  {/* <!--Middle Part Start--> */}
                  <div className="col-sm-9" id="content">
                    <h2 className="title">My Account</h2>
                    <p className="lead">
                      Hello, <strong>{username}</strong> - To update your
                      account information.
                    </p>
                    <form onSubmit={handleSubmit}>
                      <hr />
                      <div className="row">
                        {/* Billing Address */}
                        <div className="col-sm-6">
                          <fieldset id="address">
                            <legend>Billing Address</legend>
                            <div className="form-group">
                              <label className="control-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Other Billing Fields */}
                            <div className="form-group required">
                              <label className="control-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Pin Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pin Code"
                                name="pincode"
                                value={formData.pincode}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">State</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Land Mark</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Landmark"
                                name="landmark"
                                value={formData.landmark}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                name="phonenumber"
                                value={formData.phonenumber}
                                onChange={handleChange}
                              />
                            </div>
                          </fieldset>
                        </div>
                        {/* Shipping Address */}
                        <div className="col-sm-6">
                          <fieldset id="shipping-address">
                            <legend>
                              Shipping Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label>
                                <input
                                  type="checkbox"
                                  checked={sameAsBilling}
                                  onChange={handleCheckboxChange}
                                />
                                <span> Same as Billing address</span>
                              </label>
                            </legend>
                            <div className="form-group">
                              <label className="control-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="shipping_name"
                                value={formData.shipping_name}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                name="shipping_address"
                                value={formData.shipping_address}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="shipping_city"
                                value={formData.shipping_city}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Pin Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pin Code"
                                name="shipping_pincode"
                                value={formData.shipping_pincode}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">State</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                name="shipping_state"
                                value={formData.shipping_state}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Landmark</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Landmark"
                                name="shipping_landmark"
                                value={formData.shipping_landmark}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                name="shipping_phonenumber"
                                value={formData.shipping_phonenumber}
                                onChange={handleChange}
                                disabled={sameAsBilling}
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      <div className="buttons">
                        <button type="submit" className="btn btn-primary">
                          Save Address
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        {/* </>
      )} */}
    </>
  );
};

export default Myaccount;
