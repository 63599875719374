import React,{useState,useEffect} from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import Loader from '../Components/Loader';

import Header from '../Components/Header';
import Footer from '../Components/Footer';


const PrivacyPolicy = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      // Simulate a network request or loading process
      const timer = setTimeout(() => setIsLoading(false), 3000);
      return () => clearTimeout(timer);
      }, []);
  
  return (
    <>
    {/* {isLoading ? (
        <Loader />
      ) : ( 
        <>*/}
    <div className="common-home res layout-home1">
      <div id="wrapper" className="wrapper-full banners-effect-7">
        <Header />
        <div class="main-container container">
          <ul class="breadcrumb">
            <li>
              <a>
                <i class="fa fa-home"></i>
              </a>
            </li>
            <li>Privacy Policy</li>
          </ul>

          <div class="row">
            <div id="content" class="col-sm-12">

             
            <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={12}>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title className="text-center mb-4">Privacy Policy</Card.Title>
              
              <p>
                United Paper is committed to protecting your privacy. When you make an online order, we need to know your name, 
                e-mail address, delivery address, phone number, and associated details. This allows us to process and fulfill your 
                orders and to notify you about your order status.
              </p>

              <p>
                We will also require a landline telephone number in case we need to contact you, and for international orders, 
                this number may be given to our couriers to help with the delivery of your goods. You have trusted us by giving 
                us these details, and we promise not to use them for any other reason other than processing your order or 
                informing you about our products and services.
              </p>

              <p>
                We will not pass any of your details on to third parties for profit or otherwise. Any information provided by you 
                will be used for contact purposes only. However, we may need to provide your name and delivery address to third 
                parties involved in processing your order or delivering services to you.
              </p>

              <p>
                Your information will not be shared with individuals or other companies (such as direct mail organizations or 
                other third parties) unless required by law.
              </p>

              <p>
                When additional information is requested, we will try to let you know at the time of collection how we intend 
                to use the personal information you provide, such as responding to your inquiry, accepting an order, conducting 
                a survey, or allowing you to access specific information like account details. We do our best to maintain the 
                accuracy of any personal information supplied to us.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>




            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    {/* </>
	  )} */}
</>  
);
};

export default PrivacyPolicy;
