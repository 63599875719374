import React, { useState,useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { ClipLoader } from "react-spinners";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    // Input validation
    if (!email || !password) {
      setError("Email and Password are required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://unitedmart.in/customerapp/login.php",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check response for success   
      if (response.data.head.code === 200) {
        const userData = response.data.body;
        const userId = response.data.body?.user_id;
        const Email = response.data.body?.email

        localStorage.setItem("user_id", userId);
        const username = response.data.body?.name;
        localStorage.setItem("name", username);
        localStorage.setItem('email', Email);
        setSuccess("Login successful!");
        alert("Log in successful");
        navigate("/");
        console.log("User Data:", userData);

        // Save user data to local storage (optional)
        localStorage.setItem("user", JSON.stringify(userData));
      } else {
        setError(response.data.head.msg || "Login failed.");
      }
    } catch (err) {
      console.error("Login Error:", err);
      setError("Something went wrong. Please try again later.");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    // Input validation
    if (!forgotEmail) {
      setError("Email is required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://unitedmart.in/customerapp/forgot_password.php",
        { email: forgotEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.head.code === 200) {
		alert("Password Send To Your Email")
		setLoading(false);
		setSuccess("Password Send To Your Email");
        setIsForgotPassword(false);
        setForgotEmail("");
      } else {
        setError(response.data.head.msg || "Failed to send reset email.");
      }
    } catch (err) {
      console.error("Forgot Password Error:", err);
      setError("Something went wrong. Please try again later.");
    }
  };
   useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);
  return (
    <>
      <div className="common-home res layout-home1">
        <div id="wrapper" className="wrapper-full banners-effect-7">
          <Header />
          <div className="main-container container">
            <ul className="breadcrumb">
              <li>
                <a href="/">
                  <i className="fa fa-home"></i>
                </a>
              </li>
              <li>
                <a href="/login">Login</a>
              </li>
            </ul>

            <div className="row">
              <div id="content" className="col-sm-12">
                <div className="page-login">
                  <div className="account-border">
                    <div className="row">
                      {!isForgotPassword ? (
                        <>
                          {/* Login Form */}
						  <div class="col-sm-6 new-customer">
								<div class="well">
									<h2><i class="fa fa-file-o" aria-hidden="true"></i> New Customer</h2>
									<p>By creating an account you will be able to shop faster, be up to date on an order's status.</p>
								</div>
								<div class="bottom-form">
									<Link to = '/register' class="btn btn-default pull-right">Register</Link>
								</div>
							</div>
                          <div className="col-sm-6 customer-login">
                            <form onSubmit={handleLogin}>
                              <div className="well">
                                <h2>
                                  <i
                                    className="fa fa-file-text-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Existing Customer
                                </h2>
                                <p>
                                  <strong>I am an existing customer</strong>
                                </p>
                                {error && (
                                  <p style={{ color: "red" }}>{error}</p>
                                )}
                                {success && (
                                  <p style={{ color: "green" }}>{success}</p>
                                )}
                                <div className="form-group">
                                  <label
                                    className="control-label"
                                    htmlFor="input-email"
                                  >
                                    E-Mail Address
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={(e) =>
                                      setEmail(e.target.value)
                                    }
                                    id="input-email"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="control-label"
                                    htmlFor="input-password"
                                  >
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    id="input-password"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="bottom-form">
                                <a
                                  
                                  className="forgot"
                                  onClick={() => setIsForgotPassword(true)}
                                >
                                  Forgot Password?
                                </a>
                                <button
                                  type="submit"
                                  className="btn btn-default pull-right"
                                >
                                  Login
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Forgot Password Form */}
                          <div className="col-sm-6 customer-login">
                            <form onSubmit={handleForgotPassword}>
                              <div className="well">
                                <h2>
                                  <i
                                    className="fa fa-file-text-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Forgot Password
                                </h2>
                                <p>
                                  <strong>Enter your register email to reset password</strong>
                                </p>
                                {error && (
                                  <p style={{ color: "red" }}>{error}</p>
                                )}
                                {success && (
                                  <p style={{ color: "green" }}>{success}</p>
                                )}
                                <div className="form-group">
                                  <label
                                    className="control-label"
                                    htmlFor="forgot-email"
                                  >
                                    E-Mail Address
                                  </label>
                                  <input
                                    type="email"
                                    name="forgotEmail"
									placeholder="Enter your register email"
                                    value={forgotEmail}
                                    onChange={(e) =>
                                      setForgotEmail(e.target.value)
                                    }
                                    id="forgot-email"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="bottom-form">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={() => setIsForgotPassword(false)}
                                >
                                  Back to Login
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-default pull-right"
                                >
										{loading ? (
										<ClipLoader color="#fff" size={16} />
									) : (
                                 " Submit"
									)}
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Login;
