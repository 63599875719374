import React, { useState } from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    company_name:"",
    email: "",
    phno: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    password: "",
    confirm: "",
    customer_by:"online",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/;
    const mobileRegex = /^\d{10}$/;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Email must be a valid Gmail or Yahoo address.";
    }

    if (!formData.phno.trim()) {
      newErrors.phno = "Mobile number is required.";
    } else if (!mobileRegex.test(formData.phno)) {
      newErrors.phno = "Mobile number must be 10 digits.";
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required.";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }

    if (!formData.confirm.trim()) {
      newErrors.confirm = "Password confirmation is required.";
    } else if (formData.password !== formData.confirm) {
      newErrors.confirm = "Passwords do not match.";
    }

    if (!captchaValue) {
      newErrors.captcha = "Captcha verification is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://unitedmart.in/customerapp/register.php",
          JSON.stringify({
            ...formData,
            recaptcha_token: captchaValue,
          }),
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.data.head && response.data.head.msg === "success") {
          setSuccessMessage("Registration successful!");
          setFormData({
            name: "",
            company_name:"",
            email: "",
            phno: "",
            address: "",
            city: "",
            state: "",
            pincode: "",
            password: "",
            confirm: "",
            customer_by:"",
          });
          setCaptchaValue(null);
          alert("Registered successfully");
          navigate('/login');
        } else {
          setErrors({ apiError: response.data.head.msg || "Registration failed." });
        }
      } catch (error) {
        setErrors({ apiError: "An error occurred. Please try again later." });
      }
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error for the current field
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <>
      <div className="common-home res layout-home1">
        <div id="wrapper" className="wrapper-full banners-effect-7">
          <Header />
          <div className="main-container container">
            <ul className="breadcrumb">
              <li><a href="#"><i className="fa fa-home"></i></a></li>
              <li><a href="#">Account</a></li>
              <li><a href="#">Register</a></li>
            </ul>

            <div className="row">
              <div id="content" className="col-sm-12">
                <h2 className="title">Register Account</h2>
                <p>If you already have an account with us, please login at the <Link to='/login'>login page</Link>.</p>
                <form onSubmit={handleSubmit} className="form-horizontal account-register clearfix">
                  <fieldset id="account">
                    <legend>Your Personal Details</legend>

                    {errors.apiError && <div className="error-message" style={{ color: "red" }}>{errors.apiError}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                     
                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-companyname">Company Name</label>
                      <div className="col-sm-10 col-lg-6">
                      <input
  type="text"
  name="company_name"  // Ensure name matches state key
  value={formData.company_name}
  placeholder="Company Name"
  id="input-name"
  className="form-control"
  onChange={handleChange}
/>

                        {errors.company_name && <div className="error-message" style={{ color: "red" }}>{errors.company_name}</div>}
                      </div>
                    </div>

                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-name">Name</label>
                      <div className="col-sm-10 col-lg-6">
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          placeholder="Name"
                          id="input-name"
                          className="form-control"
                          onChange={handleChange}
                        />
                        {errors.name && <div className="error-message" style={{ color: "red" }}>{errors.name}</div>}
                      </div>
                    </div>

                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-email">E-Mail</label>
                      <div className="col-sm-10 col-lg-6">
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          placeholder="E-Mail"
                          id="input-email"
                          className="form-control"
                          onChange={handleChange}
                        />
                        {errors.email && <div className="error-message" style={{ color: "red" }}>{errors.email}</div>}
                      </div>
                    </div>

                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-phno">Mobile</label>
                      <div className="col-sm-10 col-lg-6">
                        <input
                          type="tel"
                          name="phno"
                          value={formData.phno}
                          placeholder="Mobile"
                          id="input-phno"
                          className="form-control"
                          onChange={handleChange}
                        />
                        {errors.phno && <div className="error-message" style={{ color: "red" }}>{errors.phno}</div>}
                      </div>
                    </div>

                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-password">Password</label>
                      <div className="col-sm-10 col-lg-6">
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          placeholder="Password"
                          id="input-password"
                          className="form-control"
                          onChange={handleChange}
                        />
                        {errors.password && <div className="error-message" style={{ color: "red" }}>{errors.password}</div>}
                      </div>
                    </div>

                    <div className="form-group required">
                      <label className="col-sm-2 control-label" htmlFor="input-confirm">Confirm Password</label>
                      <div className="col-sm-10 col-lg-6">
                        <input
                          type="password"
                          name="confirm"
                          value={formData.confirm}
                          placeholder="Confirm Password"
                          id="input-confirm"
                          className="form-control"
                          onChange={handleChange}
                        />
                        {errors.confirm && <div className="error-message" style={{ color: "red" }}>{errors.confirm}</div>}
                      </div>
                    </div>

                    <div id="captcha" className="my-3 recaptcha">
                      <ReCAPTCHA
                        sitekey="6Lf6kbQqAAAAAMqTnTRV5nFHZp7EChvBHfS8zObJ"
                        onChange={handleCaptchaChange}
                      />
                      {errors.captcha && <div className="error-message" style={{ color: "red" }}>{errors.captcha}</div>}
                    </div>

                    <div className="buttons">
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Register;
