import React,{useState,useEffect, useRef, useContext} from 'react'
import { Link } from 'react-router-dom';
import { useLocation,useNavigate } from 'react-router-dom';
import { Offcanvas, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Logo from'../Assets/united-logo.png';
import CatogoryImage from '../Assets/menu-icon.png'
import { CartContext } from '../Context/CartContext'


const Header = () => {
	
const username = localStorage.getItem('name');
const UserId = localStorage.getItem('user_id');
const [showOffcanvas, setShowOffcanvas] = useState(false);
const [isMegaMenuActive, setMegaMenuActive] = useState(false);
const [activeTab, setActiveTab] = useState(null); // Tracks the currently active tab
const [categories, setCategories] = useState([]);
const [searchQuery, setSearchQuery] = useState("");
const menuRef = useRef(null);
const [subMenu, setSubMenu] = useState([]);
const { handleLogout } = useContext(CartContext);
const [wishlistLength, setWishlistLength] = useState(0);
const navigate = useNavigate();

const [showAllCategories, setShowAllCategories] = useState(false); // Toggle state
const [homeCategory, setHomeCategory] = useState([]);
const [electronicCategory, setElectronicCategory] = useState([]);
const [kitchenCategory, setKitchenCategory] = useState([]);
const [accessoriesCategory, setAccessoriesCategory] = useState([]);
const [isCartOpen, setIsCartOpen] = useState(false);
const toggleCart = () => {
	setIsCartOpen(!isCartOpen);
  };
const toggleCategories = () => {
  setShowAllCategories((prevState) => !prevState); // Toggle the state
};

const handleMouseEnter = (tabIndex) => {
  setActiveTab(tabIndex); 
};

const handleMouseLeave = () => {
  setActiveTab(null);
};

const handleClickOutside = (event) => {
  if (menuRef.current && !menuRef.current.contains(event.target)) {
	setActiveTab(null); 
  }
};

useEffect(() => {
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
	document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

  // Toggle Mega Menu
  const handleToggleMenu = () => {
    setMegaMenuActive(!isMegaMenuActive);
  };

  // Close Mega Menu
  const handleCloseMenu = () => {
    setMegaMenuActive(false);
  };
	
  const handleOffcanvasToggle = () => {
		setShowOffcanvas(!showOffcanvas);
  };
  const handleCheckout = () => {
    const userId = localStorage.getItem("user_id");
    if (!userId || userId === "0") {
      navigate("/login"); // Redirect to the login page
    } else {
      navigate("/checkout"); // Redirect to the checkout page
    }
  };

  const handleWishlist = () => {
    const userId = localStorage.getItem("user_id");
    if (!userId || userId === "0") {
      navigate("/login"); // Redirect to the login page
    } else {
      navigate("/wishlist"); // Redirect to the checkout page
    }
  };

  const location = useLocation(); // Get the current route
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Set initial state based on the current path
    if (location.pathname === '/') {
      setIsMenuOpen(true); // Open the menu on the home page
    } else {
      setIsMenuOpen(false); // Close the menu on other pages
    }
  }, [location.pathname]); // Re-run when the route changes

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
 


  useEffect(() => {
	const fetchCategories = async () => {
	  try {
		const response = await axios.post('https://unitedmart.in/customerapp/header.php');
		if (response.data.head && response.data.head.code === 200) {
		  setCategories(response.data.body.all_sub_category || []);
		  setElectronicCategory(response.data.body.electronic_category || []);
		  setHomeCategory(response.data.body.home_category || []);
		  setKitchenCategory(response.data.body.kitchen_category || []);
		  setAccessoriesCategory(response.data.body.accessories_category || []);
		} else {
		  console.error('Failed to fetch categories:', response.data.head.msg);
		}
	  } catch (error) {
		console.error('Error fetching categories:', error);
	  }
	};
  
	fetchCategories();
  }, []);
  





  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };



  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== "") {
      navigate(`/shop?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  const { cartItems, removeItemFromCart, cartCount } = useContext(CartContext);

  // Calculate subtotal and GST
  const subTotal = cartItems.reduce((acc, item) => acc + item.onlinerate * item.qty, 0);
  const cgst =cartItems.reduce((acc, item) => acc + item.tax_amt / 2 * item.qty, 0);
  const sgst = cartItems.reduce((acc, item) => acc + item.tax_amt / 2 * item.qty, 0);
  const total = subTotal + cgst + sgst;


  const menu  =[
   "Shop By Categories",
//    "Home Appliances",
//    "Kitchen Appliances",
//    "Accessories"
  ]

  function capitalizeFirstLetter(str) {
	if (!str) return ''; 
	return str
	  .split(' ') 
	  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
	  .join(' '); 
  }
  

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      const storedWishlistLength = localStorage.getItem(`wishlistLength_${userId}`);
      if (storedWishlistLength) {
        setWishlistLength(parseInt(storedWishlistLength, 10)); // Set the length from localStorage
      }
    }
  }, []);
  const activeLink = location.pathname; // Get the current active route


  return (
    
	<>

{/* 	<!-- Header Container  -->
 */}	<header id="header" class=" variantleft type_1">
	<div class="header-top">
		<div class="container">
			<div class="row">
				<div class="header-top-left form-inline col-lg-6 col-md-5 col-sm-6 compact-hidden hidden-sm ">
					<div class="form-group navbar-welcome " >
						Welcome to <strong> &nbsp;United Mart.In</strong>&nbsp;
						{UserId ?(
							<strong></strong>
						):(
							<Link to = '/login'><strong> - Sign In</strong></Link>	
						)}					
					</div>
				</div>
				<div class="header-top-right collapsed-block text-right  col-sm-6 col-xs-12 ">
				{/* <h5 class="tabBlockTitle visible-xs">More<a class="expander " href="#TabBlock-1"><i class="fa fa-angle-down"></i></a></h5> */}
				{/* <div class="tabBlock" id="TabBlock-1"> */}
					<ul class="top-link list-inline">
						<li class="account" id="my_account">
							<a href="#" title="My Account" class="btn btn-xs dropdown-toggle" data-toggle="dropdown"> <span >My Account</span> <span class="fa fa-angle-down"></span></a>
							<ul class="dropdown-menu ">
								{UserId ?(
									<>
							<li ><a style={{color:"#3f3838"}}> <b><i class="fa fa-user"></i> {username}</b></a></li>
							<li><Link to = '/myaccount' style={{color:"#3f3838"}}><i class="fa fa-pencil-square-o"></i> My Account</Link></li>
							<li><Link to = '/orderhistory' style={{color:"#3f3838"}}><i class="fa fa-pencil-square-o"></i> Your Orders</Link></li>

								
								<li><a onClick={handleLogout}><i class="bi bi-box-arrow-left"></i> Logout</a></li>
								</>
					):(
						<>
						<li><Link to = '/register'><i class="fa fa-pencil-square-o"></i> Register</Link></li>
								  
								<li><Link to="/login"><i class="bi bi-box-arrow-right"></i> Login</Link></li>
						</>
					)}
							</ul>
						</li>
						<li class="wishlist"><a onClick={handleWishlist} id="wishlist-total" class="top-link-wishlist"><span>Wish List </span></a></li>
						<li class="checkout"><Link to = '/checkout' class="top-link-checkout" title="Checkout"><span >Checkout</span></Link></li>
						<li class="login"><Link to ='/cart' title="Shopping Cart"><span >Shopping Cart</span></Link></li>
					
						</ul>
				</div>
			</div>
			{/* </div> */}
		</div>
	</div>
	{/* <!-- //Header Top -->
 */}
{/* <!-- Header center --> */}
<div class="header-center left">
	<div class="container">
		<div class="row">
			{/* <!-- Logo --> */}
			<div class="navbar-logo col-md-2 col-sm-12 col-xs-12">
				<Link to = '/'><img src={Logo} title="united" alt="united" /></Link>
			</div>
			{/* <!-- //end Logo --> */}

			{/* <!-- Search --> */}
			<div id="sosearchpro" class="col-sm-6 shopcart">
			 <form onSubmit={handleSubmit}>
      			<div className="search input-group">
        			<input
         				 className="autosearch-input form-control"
          				 type="text"
           				 value={searchQuery}
                         onChange={handleInputChange}
                         size="50"
                         placeholder="Search"
                         name="search"
                    />
        			<span className="input-group-btn">
          				<button type="submit" className="button-search btn btn-primary" name="submit_search">
            				<i className="fa fa-search"></i>
          				</button>
        			</span>
      			</div>
      				<input type="hidden" name="route" value="product/search" />
    		 </form>
			</div>
			{/* <!-- //end Search --> */}

			{/* <!-- Secondary menu --> */}
			<div class="col-md-2 col-sm-5 col-xs-12 shopping_cart pull-right">
			{/* 	<!--cart--> */}
			<div id="cart" className="btn-group btn-shopping-cart">
						<a data-loading-text="Loading..." className="top_cart dropdown-toggle" data-toggle="dropdown" onClick={toggleCart}>
							<div className="shopcart">
							<span className="handle pull-left"></span>
							<span className="title">My cart</span>
							<p className="text-shopping-cart cart-total-full">
								{cartCount} item(s) - <i className="bi bi-currency-rupee"></i>{subTotal.toFixed(2)}
							</p>
							</div>
						</a>

						<ul className={`tab-content content dropdown-menu pull-right shoppingcart-box ${isCartOpen ? "show" : ""}`} role="menu">
							<li>
							<table className="table table-striped">
								<tbody>
								{cartItems.map((item, index) => (
									<tr key={index}>
									<td className="text-center" style={{ width: '70px' }}>
										<a>
										<img
											src={item.image}
											style={{ width: '70px' }}
											alt={item.product_name}
											title={item.product_name}
											className="preview"
										/>
										</a>
									</td>
									<td className="text-left">
										<a className="cart_product_name" href="products.html">
										{item.product_name}
										</a>
									</td>
									<td className="text-center">x{item.qty}</td>
									<td className="text-center">
										<nobr><i className="bi bi-currency-rupee"></i>{Number(item.onlinerate * item.qty).toFixed(2)}</nobr>
									</td>
									<td className="text-right">
										<a href="products.html" className="fa fa-edit"></a>
									</td>
									<td className="text-right">
										<button
										onClick={() => removeItemFromCart(item.product_id, item.image)}
										className="fa fa-times fa-delete"
										></button>
									</td>
									</tr>
								))}
								</tbody>
							</table>
							</li>
							<li>
							<div>
								<table className="table table-bordered">
								<tbody>
									<tr>
									<td className="text-left"><strong>Sub-Total</strong></td>
									<td className="text-right"><i className="bi bi-currency-rupee"></i>{subTotal.toFixed(2)}</td>
									
									</tr>
									<tr>
									<td className="text-left"><strong></strong></td>
									<td className="text-right">(Exclusive of all taxes)</td>
									</tr>
									{/* <tr>
									<td className="text-left"><strong>CGST</strong></td>
									<td className="text-right"><i className="bi bi-currency-rupee"></i>{cgst.toFixed(2)}</td>
									</tr>
									<tr>
									<td className="text-left"><strong>SGST</strong></td>
									<td className="text-right"><i className="bi bi-currency-rupee"></i>{sgst.toFixed(2)}</td>
									</tr>
									<tr>
									<td className="text-left"><strong>Total</strong></td>
									<td className="text-right"><i className="bi bi-currency-rupee"></i>{total.toFixed(2)}</td>
									</tr> */}
								</tbody>
								</table>
								<p className="text-right">
								<Link to="/cart" className="btn view-cart" onClick={() => setIsCartOpen(false)}>
									<i className="fa fa-shopping-cart"></i>View Cart
								</Link>
								&nbsp;&nbsp;&nbsp;
								<Link to="#" className="btn btn-mega checkout-cart" onClick={(e) => { e.preventDefault(); setIsCartOpen(false); handleCheckout(); }}>
                  <i className="fa fa-share"></i> Checkout
                </Link>
								</p>
							</div>
							</li>
						</ul>
						</div>
				{/* <!--//cart--> */}
			</div>
		</div>

	</div>
</div>
{/* <!-- //Header center --> */}
{/* 
<!-- Header Bottom --> */}
<div class="header-bottom">
	<div class="container">
		<div class="row">
			<div class="sidebar-menu col-md-2 col-sm-6 col-xs-6 "></div>
			{/* <div class="sidebar-menu col-md-3 col-sm-6 col-xs-6 ">
				<div class="responsive so-megamenu ">
					<div class="so-vertical-menu no-gutter ">
						<nav class="navbar-default">	
							
							<div className={`container-megamenu vertical ${isMenuOpen ? "open" : "closed"}`}>
      							<div id="menuHeading">

        							<div className="megamenuToogle-wrapper">
          								<div className="megamenuToogle-pattern" onClick={toggleMenu}>
            								<div className="container">
              									<div>
                									<span></span>
                									<span></span>
                									<span></span>
              									</div>  
              									All Categories
              								<i
                							className={`fa pull-right arrow-circle ${
                  							isMenuOpen ? "fa-chevron-circle-down" : "fa-chevron-circle-up"
                							}`}
              								></i>
           								    </div>
          								</div>
        							</div>
      							</div>
								  <div class="navbar-header">
									<button type="button" id="show-verticalmenu" data-toggle="collapse" class="navbar-toggle fa fa-list-alt" onClick={handleOffcanvasToggle}>
										
									</button>
									All Categories		
								</div>
								{isMenuOpen && (
									<div className="vertical-wrapper">
										<span id="remove-verticalmenu" className="fa fa-times"></span>
										<div className="megamenu-pattern">
										<div className="container">
											<ul
											className="megamenu"
											style={{
												maxHeight: "438px", // Set a fixed height
												overflowY: "scroll", // Enable vertical scrolling
												paddingRight: "10px", // Adjust padding for scrollbar
											}}  
											>
											
											{categories.map((category) => (
												<li
												key={category.subcategory_id}
												className="item-vertical style1 with-sub-menu hover"
												>
												<Link
													to={`/products/${category.subcategory_id}`}
													className="clearfix"
												>
													<img
													src={CatogoryImage}
													alt={category.subcategory_name}
													/>
													<span>{capitalizeFirstLetter(category.subcategory_name)}</span>
												</Link>
												</li>
											))}
											</ul>
										</div>
										</div>
									</div>
									)}
    							</div>
							</nav>
					</div>
				</div>

			</div> */}
			
			{/* <!-- Main menu --> */}
			<div class="megamenu-hori header-bottom-right  col-md-9 col-sm-6 col-xs-6">
				<div class="responsive so-megamenu ">
	<nav class="navbar-default">
		<div class=" container-megamenu  horizontal">
			<div class="navbar-header">
				<button type="button" id="show-megamenu" data-toggle="collapse" class="navbar-toggle menu-icon" onClick={handleToggleMenu}>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				Menu		
			</div>
			
			<div className={`megamenu-wrapper ${isMegaMenuActive ? 'so-megamenu-active' : ''}`}>
				<span id="remove-megamenu" class="fa fa-times" onClick={handleCloseMenu}></span>
				<div class="megamenu-pattern">
					<div class="container">
						{/* <ul class="megamenu " data-transition="slide" data-animationtime="250">
							<li class="home hover">
								<Link to = "/">Home</Link>
							
							</li>
											
					{subMenu.map((category, index) => (
						<li
							key={index}
							className={`with-sub-menu hover ${activeTab === index ? 'active' : ''}`}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLea  ve={handleMouseLeave}
						>
							<p className="close-menu"></p>
							<a href="#" className="clearfix">
							<strong>{category.category_name}</strong>
							<b className="caret"></b>
							</a>
							<div
							className="sub-menu"
							style={{ width: '100%', display: activeTab === index ? 'block' : 'none' }}
							>
							<div className="content" style={{ display: activeTab === index ? 'block' : 'none' }}>
								<div className="row">
								<div className="col-md-12">
									<div className="row">
									
									{(category.subcategory || []).map((subcategory, subIndex) => (
										<div key={subIndex} className="col-md-4 static-menu">
										<div className="menu">
											<ul>
											<li>
											<Link to={`/products/${subcategory.subcategory_id}`} className="main-menu">
												{subcategory.subcategory_name}
												</Link>
											</li>
											</ul>
										</div>
										</div>
									))}
									</div>
								</div>
								</div>
							</div>
							</div>
						</li>
					))}
							<li class="">
								<p class="close-menu"></p>
								<Link to="/contact" class="clearfix">
									<strong>Contact Us</strong>
									<span class="label"></span>
								</Link>
							</li>
							
							
						</ul> */}
						<ul class="megamenu " data-transition="slide" data-animationtime="250">
							<li className={`home hover ${activeLink === '/' ? 'active' : ''}`}>
								<Link to = "/">Home</Link>
							
							</li>
								{menu.map((category, index) => (
									<li key={index} className={`with-sub-menu hover ${activeTab === index ? 'active' : ''}`}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}>
									<p className="close-menu"></p>
									<a className="clearfix">
										<strong>{category}</strong>
										<b className="caret"></b>
									</a>

									<div className="sub-menu" style={{ width: '100%', display: activeTab === index ? 'block' : 'none' }}>
										<div className="content" style={{ display: activeTab === index ? 'block' : 'none' }}>
										<div className="row">
											<div className="col-md-12">
											<div className="row">
												{/* Dynamically load categories based on the menu index */}
												{index === 0 && categories.length > 0 && categories.map(category => (
													<div key={category.subcategory_id} className="col-md-4 static-menu">
														<div className="menu">
														<ul>
															<li>
															<Link to={`/products/${category.subcategory_id}`} className="subcategory-link" reloadDocument>
																{capitalizeFirstLetter(category.subcategory_name)}
															</Link>
															{category.subcategory && category.subcategory.length > 0 && (
																<ul>
																{category.subcategory.map(subcategory => (
																	<li key={subcategory.subcategory_id}>
																	<Link to={`/products/${subcategory.subcategory_id}`} className="subcategory-link">
																		{capitalizeFirstLetter(subcategory.subcategory_name)}
																	</Link>
																	</li>
																))}
																</ul>
															)}
															</li>
														</ul>
														</div>
													</div>
													))}


												{/* {index === 1 && homeCategory.length > 0 && homeCategory.map(category => (
												<div key={category.category_id} className="col-md-4 static-menu">
													<div className="menu">
													<ul>
														<li>
														<a  className="main-menu"  style={{cursor:"default"}}>
															{category.category_name}
														</a>
														<ul>
															{category.subcategory.map(subcategory => (
															<li key={subcategory.subcategory_id}>
																<Link to={`/products/${subcategory.subcategory_id}`} className="subcategory-link">
																{capitalizeFirstLetter(subcategory.subcategory_name)}
																</Link>
															</li>
															))}
														</ul>
														</li>
													</ul>
													</div>
												</div>
												))} */}

												{/* {index === 2 && kitchenCategory.length > 0 && kitchenCategory.map(category => (
												<div key={category.category_id} className="col-md-4 static-menu">
													<div className="menu">
													<ul>
														<li>
														<a className="main-menu" style={{cursor:"default"}}>
															{category.category_name}
														</a>
														<ul>
															{category.subcategory.map(subcategory => (
															<li key={subcategory.subcategory_id}>
																<Link to={`/products/${subcategory.subcategory_id}`} className="subcategory-link">
																{capitalizeFirstLetter(subcategory.subcategory_name)}
																</Link>
															</li>
															))}
														</ul>
														</li>
													</ul>
													</div>
												</div>
												))} */}

												{/* {index === 3 && accessoriesCategory.length > 0 && accessoriesCategory.map(category => (
												<div key={category.category_id} className="col-md-4 static-menu">
													<div className="menu">
													<ul>
														<li>
														<a className="main-menu"  style={{cursor:"default"}}>
															{category.category_name}
														</a>
														<ul>
															{category.subcategory.map(subcategory => (
															<li key={subcategory.subcategory_id}>
																<Link to={`/products/${subcategory.subcategory_id}`} className="subcategory-link">
																{capitalizeFirstLetter(subcategory.subcategory_name)}
																</Link>
															</li>
															))}
														</ul>
														</li>
													</ul>
													</div>
												</div>
												))} */}
											</div>
											</div>
										</div>
										</div>
									</div>
									</li>
								))}
								<li className={`${activeLink === '/wishlist' ? 'active' : ''}`}>
								<p class="close-menu"></p>
								<Link to="/wishlist" class="clearfix">
									<strong>Wish List</strong>
									<span class="label"></span>
								</Link>
							</li>
								<li className={`${activeLink === '/aboutus' ? 'active' : ''}`}>
								<p class="close-menu"></p>
								<Link to="/aboutus" class="clearfix">
									<strong>About Us</strong>
									<span class="label"></span>
								</Link>
							</li>
								<li className={`${activeLink === '/contact' ? 'active' : ''}`}>
								<p class="close-menu"></p>
								<Link to="/contact" class="clearfix">
									<strong>Contact Us</strong>
									<span class="label"></span>
								</Link>
							</li>
						</ul>
        			</div>
				</div>
			</div>
		</div>
	</nav>
  </div>
</div>
			{/* <!-- //end Main menu --> */}
			
		</div>
	</div>

</div>
{/* 
<!-- Navbar switcher -->
<!-- //end Navbar switcher --> */}
	</header>

	{/* -------------all cateogories offcanvas side menu------------------- */}
	<Offcanvas show={showOffcanvas} onHide={handleOffcanvasToggle} placement="start" style={{ width: '250px' }}>
	
	<Offcanvas.Header>
	<Offcanvas.Title>Categories</Offcanvas.Title>
          <Button
            variant="link"
            className="text-muted"
            style={{ fontSize: '24px', marginLeft: 'auto' }}
            onClick={handleOffcanvasToggle}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          
        </Offcanvas.Header>

        <Offcanvas.Body>
          <ul className="list-unstyled">
		  {categories.map((category) => (
            <li key={category.subcategory_id} >
              <Link to={`/products/${category.subcategory_id}`}className="d-flex align-items-center offcanvas-li">
                <img
                  src="assets/images/icons/2.png"
                  alt="icon"
                  style={{ width: '10px', marginRight: '10px' }}
                />
                {category.subcategory_name}
              </Link>
            </li>
		  ))}
           
            
            {/* Add more categories here */}
            <li>
              <Button variant="link" className="text-primary">
                <i className="fa fa-plus-square-o"></i> More Categories
              </Button>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
</>
  )
}

export default Header