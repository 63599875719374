import React from 'react';
import './Loader.css'; // Import the loader styles
import image from '../Assets/united-logo.png'

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="navbar-logo col-md-3 col-sm-12 col-xs-12">
				<img src={image} title="united" alt="united"/>
			</div>
    </div>
  );
};

export default Loader;
