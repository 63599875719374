import React,{useState,useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import Loader from '../Components/Loader';



const Terms = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      // Simulate a network request or loading process
      const timer = setTimeout(() => setIsLoading(false), 3000);
      return () => clearTimeout(timer);
      }, []);
  
  return (
    <>
   
    <div className="common-home res layout-home1">
      <div id="wrapper" className="wrapper-full banners-effect-7">
        <Header />
        <div class="main-container container">
          <ul class="breadcrumb">
            <li>
              <a >
                <i class="fa fa-home"></i>
              </a>
            </li>
            <li>Terms & Conditions</li>
          </ul>

          <div class="row">
            <div id="content" class="col-sm-12">
            <Container className="terms-container">
      <Row>
        <Col>
          <Card className="terms-card">
            <Card.Body>
 <Card.Title className="text-center mb-4">Terms & Conditions</Card.Title>              
 <Card.Text className="terms-text">
              
                <ListGroup variant="flush" className="terms-list">
                <p>
                  We aim to create our products to a high standard and to ship them to you in tip-top condition.
                  </p>
                  <p>
                    If you experience any dissatisfaction with the product, please contact us and we will assess your claim personally.
                  </p>
                  <p>
                    If an item is assessed as faulty by us, we reserve the right to issue either a full or part refund via cash or credit note depending on the nature of the claim.
                  </p>
                  <p>
                    Products are handmade specifically for your order, therefore we do not refund for change of mind.
                  </p>
                  <p>
                    Product choice is subject to availability. If we are unable to meet your order request, we will contact you and discuss alternatives or offer you a full refund, whichever is agreeable to you.
                  </p>
                  <p>
                    If you would like any of our designs custom-made to your own specifications, please get in contact with us, and we can discuss design variation options with you. Variations can include paper.
                  </p>
                  <p>
                    We follow appropriate safeguards for the protection of personal information that is collected, held, and used by the company. We keep confidential and private the personal data of our customers and suppliers consistent with applicable laws.
                  </p>
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>




            </div>
           </div>
        </div>
        <Footer />
    </div>
</div> 

</>
  )
}

export default Terms