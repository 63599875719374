import React,{useState,useEffect,useContext} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Link } from "react-router-dom";
import { CartContext } from "../Context/CartContext";


const Wishlist = () => {
	const [wishlist, setWishlist] = useState([]);
	const { addItemToCart } = useContext(CartContext);
  const userId = localStorage.getItem('user_id');

console.log(wishlist);


const handleAddToCart = (product) => {
  console.log("Raw qty value:", product.qty, "Type:", typeof product.qty);

  // Ensure qty is correctly converted to a number
  const quantity = parseInt(product.qty, 10) || 1;

  console.log("Converted qty:", quantity, "Type:", typeof quantity);

  const cartItem = {
    product_id: product.product_id,
    image: product.image,
    product_code: product.product_code,
    product_name: product.product_name,
    onlinerate: product.online_rate,
    tax_amt: parseFloat(product.tax_amt) || 0,
    qty: quantity, // Correctly converted qty
    stock: product.stock,
    minimumquantity: quantity,
  };

  // Print the final object before sending to cart
  console.log("Final cart item being added:", cartItem);

  // Add the item to the cart context
  addItemToCart(cartItem);

  // Get the current wishlist from local storage
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    alert("Please log in to add items to the cart.");
    return;
  }

  const storedWishlist =
    JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];

  // Remove the item added to the cart from the wishlist
  const updatedWishlist = storedWishlist.filter(
    (item) => item.product_id !== product.product_id
  );

  // Update the wishlist in local storage and state
  localStorage.setItem(`wishlist_${userId}`, JSON.stringify(updatedWishlist));
  setWishlist(updatedWishlist); // Update the wishlist state
  alert("Product removed from wishlist and added to cart!");
};


  
  
  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (userId) {
      const storedWishlist = JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
      setWishlist(storedWishlist);
    } else {
      console.log("No user logged in. Wishlist not loaded.");
      setWishlist([]); // Empty wishlist if no user is logged in
    }
  }, []);
  
  
    // Remove product from wishlist
    const handleRemoveFromWishlist = (productId) => {
      const userId = localStorage.getItem('user_id');
      if (!userId) {
        alert("Please log in to remove items from your wishlist.");
        return;
      }
    
      const storedWishlist = JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
      const updatedWishlist = storedWishlist.filter(
        (item) => item.product_id !== productId
      );
    
      localStorage.setItem(`wishlist_${userId}`, JSON.stringify(updatedWishlist)); // Update wishlist in localStorage
      setWishlist(updatedWishlist); // Update state
      alert("Do you want to remove the product from wishlist?");
    };
    
    useEffect(() => {
      const wishlistLength = wishlist.length;
      const userId = localStorage.getItem('user_id');
      if (userId) {
        localStorage.setItem(`wishlistLength_${userId}`, wishlistLength);
      }
      console.log(wishlistLength);
    }, [wishlist]);


	
  return (
    <>
        <div class="common-home res layout-home1">
            <div id="wrapper" class="wrapper-full banners-effect-7">
                <Header />
                <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="#"><i class="fa fa-home"></i></a></li>
			<li><a href="#">Account</a></li>
			<li><a href="#">My Wish List</a></li>
		</ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
			<div id="content" class="col-sm-12">
				<h2 class="title">My Wish List</h2>
				<div class="table-responsive">
					
				
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <td className="text-center">Image</td>
              <td className="text-left">Product Code</td>
              <td className="text-left">Product Name</td>
              <td className="text-right">Unit Price</td>
              <td className="text-right">Action</td>
            </tr>
          </thead>
          <tbody>
		  {wishlist.length > 0 ? (
            wishlist.map((product) => (
              <tr key={product.product_id}>
                <td className="text-center">
                  <Link to={`/productdetail/${product.product_id}`}>
                    <img
                      width="70px"
                      src={product.image}
                      alt={product.product_name}
                      title={product.product_name}
                    />
                  </Link>
                </td>
                <td className="text-left">{product.product_code}</td>
                <td className="text-left">
                  <Link to={`/productdetail/${product.product_id}`} style={{color:"#555555"}}>
                    {product.product_name}
                  </Link>
                </td>
                <td className="text-right">
                  <div className="price">
                    <span className="price-new">
                      <i className="bi bi-currency-rupee"></i>
                      {product.online_rate}
                    </span>
                    {product.mrp_rate > 0 && (
                      <span className="price-old">
                        <i className="bi bi-currency-rupee"></i>
                        {product.mrp_rate}
                      </span>
                    )}
                  </div>
                </td>
                <td className="text-right">
                  <button
                    className="btn btn-primary"
                    title="Add to Cart"
                    onClick={() => handleAddToCart(product)}
                  >
                    <i className="fa fa-shopping-cart"></i>
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    title="Remove"
                    onClick={() => handleRemoveFromWishlist(product.product_id)}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            ))
			) : (
        <p>No products in your wishlist.</p>
      )}
          </tbody>
		
        </table>
      
	  </div>
	</div>
  </div>
</div>
</div>
</div>
<Footer />
</>)
}

export default Wishlist