import React, { useState, useEffect, useContext } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { CartContext } from "../Context/CartContext";
// import LeftImage from "../Assets/left-image-static.png";
import "rc-slider/assets/index.css"; 
import Slider from "rc-slider";
import { Offcanvas, Button } from 'react-bootstrap';
import { BiSliderAlt } from "react-icons/bi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaShoppingCart } from 'react-icons/fa';
import { FaHeart } from "react-icons/fa";
import Loader from '../Components/Loader';
import axios from "axios";

const Shop = () => {
  const { sc_id } = useParams();
  const location = useLocation();

  const [openIndex, setOpenIndex] = useState(null);
  const [viewMode, setViewMode] = useState("grid");
  const [products, setProducts] = useState([]);
  const [latest, setLatest] = useState([]);
  const [categories, setCategories] = useState([]);
  const { setCartItems } = useContext(CartContext);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;
  const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [wishlist, setWishlist] = useState([]);
    const [wishlistmessage, setWishListMessage] = useState('');
    const [title, setTitle] = useState([]);
    const [subtitle, setSubTitle] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");


  const toggleOffcanvas = () => {
    if (window.innerWidth < 768) {
      setShowOffcanvas((prev) => !prev); // Toggle state only for screens < 768px
    }
  };	
  const handleOffcanvasToggle = () => {
		setShowOffcanvas(!showOffcanvas);
  };


  // Sorting function
  const sortProducts = (products, sortBy) => {
    switch (sortBy) {
      case "name-asc":
        return products.sort((a, b) =>
          a.product_name.localeCompare(b.product_name)
        );
      case "name-desc":
        return products.sort((a, b) =>
          b.product_name.localeCompare(a.product_name)
        );
      case "price-asc":
        return products.sort((a, b) => a.online_rate - b.online_rate);
      case "price-desc":
        return products.sort((a, b) => b.online_rate - a.online_rate);
      default:
        return products;
    }
  };


  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get current products for the current page
  const indexOfLastProduct = currentPage * productsPerPage;
const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

// Remove this line as `currentProducts` is already declared as a state variable
// const currentProducts = sortProducts(products, sortBy).slice(indexOfFirstProduct, indexOfLastProduct);

// Use setCurrentProducts to manage pagination or filtering
useEffect(() => {
  const paginatedProducts = sortProducts(products, sortBy).slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  setCurrentProducts(paginatedProducts); // Update the state
}, [currentPage, products, sortBy]);
  // Calculate total pages
  const totalPages = Math.ceil(products.length / productsPerPage);

  const toggleCategories = () => {
    setShowAllCategories((prevState) => !prevState); // Toggle the state
  };

  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("search");

 /*  useEffect(() => {
    // Fetch categories
    fetch("https://easya.in/customerapp/product.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sc_id }), // Send selected sc_id to fetch relevant products
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.head.code === 200) {
          setProducts(data.body.products);
          console.log("products", data.body.products);
          setLatest(data.body.latest);
          setTitle(data.body.top_title[0].main_category_name);
          setSubTitle(data.body.top_title[0].sub_category_name);
          setCategories(data.body.brand);
        } else {
          console.error("Error fetching products:", data.head.msg);
        }
      })
      .catch((error) => console.error("Error fetching API:", error));
  }, [sc_id]); */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          "https://unitedmart.in/customerapp/search.php",
          { search: searchQuery },
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
        console.log("Full API Response:", response.data);
  
        if (response.data.head.code === 200) {
          const productsList = response.data.body; // Assuming body contains products directly
          setProducts(productsList); // Update state
          console.log("Products Updated:", productsList);
        } else {
          console.error("API Error Message:", response.data.head.msg);
          setError(response.data.head.msg || "No products found.");
        }
      } catch (err) {
        console.error("Error Fetching Products:", err);
        setError("An error occurred while fetching products.");
      } finally {
        setLoading(false);
      }
    };
  
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery]);
  


  const handleCategoryClick = (brandName) => {
    if (!brandName) {
      console.error("Brand name is undefined or null");
      return;
    }
  
    console.log("Clicked brandName:", brandName);
    console.log("Products array:", products);
  
    const filteredProducts = products.filter((product) => {
      const productName = product.product_name?.toLowerCase() || "";
      const brandNameLowerCase = brandName.toLowerCase();
  
      console.log(
        `Checking if "${productName}" includes "${brandNameLowerCase}"`
      );
  
      return productName.includes(brandNameLowerCase);
    });
  
    setCurrentProducts(filteredProducts);
    console.log("Filtered Products:", filteredProducts);
  };
  
  
  
  // Toggle the dropdown
  const toggleMenu = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const addToCart = (product) => {
    setCartItems((prevCartItems) => {
      const existingProduct = prevCartItems.find(
        (item) => item.product_id === product.product_id
      );

      if (existingProduct) {
        // If product already exists, increase its quantity
        return prevCartItems.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      // If product does not exist, add it with a default quantity of 1
      return [
        ...prevCartItems,
        {
          ...product,
          onlinerate: product.online_rate,
          qty: Number(product.qty),
          tax_amt: product.tax_amt,
          minimumquantity: Number(product.qty),
        },
      ];
    });
    setMessage('Item Added To Cart!');
    
    setTimeout(() => {
      setMessage('');
    }, 2500);
  };


  const handleAddToWishlist = (product) => {
    const userId = localStorage.getItem("user_id");
    console.log("USERID",localStorage.getItem("user_id"));
    if (!userId) {
      alert("Please log in to add items to your wishlist.");
      return;
    }

    const storedWishlist = JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
    const isAlreadyInWishlist = storedWishlist.some(
      (item) => item.product_id === product.product_id
    );

    if (!isAlreadyInWishlist) {
      const updatedWishlist = [...storedWishlist, product];
      localStorage.setItem(`wishlist_${userId}`, JSON.stringify(updatedWishlist)); // Save to localStorage
      setWishlist(updatedWishlist); // Update state
      alert("Product added to wishlist!");
      setWishListMessage('Item Added To Wishlist');
      setTimeout(() => {
        setWishListMessage('');
      }, 2500);
    } else {
      alert("Product is already in your wishlist.");
    }
  };


  /* firstletter capital others  small */

  function capitalizeFirstLetter(str) {
    if (!str) return ''; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } 

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);



    const [range, setRange] = useState([0, 100000]); // Initial range for price filter
    const [currentProducts, setCurrentProducts] = useState(products); // Filtered product state
    
    const handleSliderChange = (value) => {
      setRange(value); // Update range when slider changes
    };
    
    const handleFilter = () => {
      const [minPrice, maxPrice] = range;
  
      const filteredProducts = products.filter((product) => {
        const productPrice = parseFloat(product.online_rate);
        return productPrice >= minPrice && productPrice <= maxPrice;
      });
  
      setCurrentProducts(filteredProducts);
    };
    
 useEffect(() => {
    // Simulate a network request or loading process
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
    }, []);
  
  return (
    <>
     {isLoading ? (
        <Loader />
      ) : (
        <>
      <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
          <Header />
          {message && (
                        <div className="message-popup">
                          <FaShoppingCart className="cart-icon-addtocart" />
                            <div className="message">{message}</div>
                              </div>
                    )}
                    {wishlistmessage && (
                        <div className="wishlist-message-popup">
                          <FaHeart className="heart-icon-addtowishlist" />
                            <div className="wishlist-message">{wishlistmessage}</div>
                              </div>
                    )}
          <div class="main-container container">
            <ul class="breadcrumb">
              <li>
                <Link to="/">
                  <i class="fa fa-home"></i>
                </Link>
              </li>
              <li>Shop</li>
              {/* <li>{subtitle}</li> */}
            </ul>

            <div class="row">
              {/* <!--Left Part Start --> */}
              <aside class="col-sm-4 col-md-3" id="column-left">
              <>
      {/* <div className="module menu-category titleLine">
       
        <div className="modcontent">
          <div className="box-category">
            <ul className="list-group">
            {categories
    .slice(0, categories.length) // Show all categories
    .map((category) => {
      const brandName = category.brand_name || "Unknown Brand"; // Fallback for undefined
      return (
        <li
          key={category.subcategory_id}
          className="hadchild"
          style={{cursor:"pointer"}}
          onClick={() => handleCategoryClick(brandName)}
        >
          {brandName}
        </li>
      );
    })}
              <li
                className="loadmore"
                onClick={toggleCategories}
                style={{ fontWeight: "bold" }}
              >
                <i className="fa fa-plus-square-o"></i>
                <span
                  className="more-view"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  {showAllCategories ? "Show Less" : "More Categories"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
<div className="module menu-category titleLine">
      <div style={{ display: "flex", alignItems: "center", whiteSpace:"nowrap",width:"100%" }} onClick={toggleOffcanvas}>
          <h3 className="modtitle modtitle-product">Price Filter 
             
          </h3>
          
          <nobr><span className="arrow ">
          <h3  className="modtitle">Filters 
          </h3>
          <BiSliderAlt style={{marginTop:"-13px",fontSize:"16px"}}/>
          </span></nobr>
          
        </div>
        </div>
      {/* Price Range Section */}
      <div
        style={{
          width: "200px",
          margin: "20px auto",
          textAlign: "center",
        }}
        className="price-filter"
      >
        {/* <h6>Price Filter</h6> */}
        <p>
          Min: <i className="bi bi-currency-rupee"></i>
          {range[0]} - Max: <i className="bi bi-currency-rupee"></i>
          {range[1]}
        </p>
        <Slider
          range
          min={0}
          max={100000}
          step={10}
          defaultValue={[0, 100000]}
          value={range}
          onChange={handleSliderChange}
          trackStyle={[{ backgroundColor: "#FF9900" }]}
          handleStyle={[
            { backgroundColor: "#FF9900", border: "none" },
            { backgroundColor: "#FF9900", border: "none" },
          ]}
        />
        <button
          onClick={handleFilter}
          style={{
            marginTop: "20px",
            backgroundColor: "#FF9900",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Go
        </button>
      </div>

      
    </>



                {/* <div class="module latest-product titleLine">
                  <h3 class="modtitle">Latest Product</h3>
                  <div class="modcontent ">
                    {Array.isArray(latest) && latest.length > 0 ? (
                      latest.map((item) => (
                        <div class="product-latest-item">
                          <Link to={`/productdetail/${item.product_id}`}>
                          <div class="media">
                            <div class="media-left">
                              <a href="#">
                                <img
                                  src={item.image}
                                  alt="Cisi Chicken"
                                  title="Cisi Chicken"
                                  class="img-responsive"
                                  style={{ width: " 100px", height: "82px" }}
                                />
                              </a>
                            </div>
                            <div class="media-body">
                              <div class="caption">
                                <h4>
                                  <a href="#">{capitalizeFirstLetter(item.product_name)}</a>
                                </h4>

                                <div class="price">
                                  <span class="price-new">
                                    <i class="bi bi-currency-rupee"></i>
                                    {item.online_rate}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <p>""</p>
                    )}
                  </div>
                </div> */}
                {/* <div class="module">
                  <div class="modcontent clearfix">
                    <div class="banners">
                      <div>
                        <a href="#">
                          <img src={LeftImage} alt="left-image" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </aside>
              {/* 	<!--Left Part End -->
			
			<!--Middle Part Start--> */}
              <div id="content" className="col-md-9 col-sm-8">
                <div className="products-category">
                  <div className="product-filter filters-panel">
                    <div className="row">
                      <div className="col-md-2 visible-lg">
                        <div className="view-mode">
                          <button
                            className={`btn btn-default grid ${
                              viewMode === "grid" ? "active" : ""
                            }`}
                            onClick={() => setViewMode("grid")}
                          >
                            <i className="fa fa-th" />
                          </button>
                          <button
                            className={`btn btn-default list ${
                              viewMode === "list" ? "active" : ""
                            }`}
                            onClick={() => setViewMode("list")}
                          >
                            <i className="fa fa-th-list" />
                          </button>
                        </div>
                      </div>
                      <div className="short-by-show form-inline text-right col-md-7 col-sm-8 col-xs-12">
                        <div className="form-group short-by">
                          <label htmlFor="input-sort">Sort By:</label>
                          <select
                            id="input-sort"
                            className="form-control"
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                          >
                            <option value="default">Default</option>
                            <option value="name-asc">Name (A - Z)</option>
                            <option value="name-desc">Name (Z - A)</option>
                            <option value="price-asc">
                              Price (Low &gt; High)
                            </option>
                            <option value="price-desc">
                              Price (High &gt; Low)
                            </option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="input-limit">Show:</label>
                          <select id="input-limit" className="form-control">
                            <option value="9">9</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                      <div className="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                        <ul className="pagination">
                          {Array.from({ length: totalPages }, (_, index) => (
                            <li
                              key={index + 1}
                              className={
                                currentPage === index + 1 ? "active" : ""
                              }
                            >
                              <span onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                              </span>
                            </li>
                          ))}
                          <li>
                            <a
                              href="#"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              &gt;
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              onClick={() => handlePageChange(totalPages)}
                            >
                              &gt;|
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={`products-list row ${viewMode}`}>
                  {currentProducts && currentProducts.length > 0 ? (
  currentProducts.map((product) => (
    <div
      key={product.id}
      className="product-layout col-md-4 col-sm-6 col-xs-12"
    >
      <div className="product-item-container">
        <div
          className={`left-block ${
            viewMode === "list" ? "col-md-4" : ""
          }`}
        >
          <Link to={`/productdetail/${product.product_id}`}>
            <div className="product-image-container">
              <img
                src={product.image}
                alt={product.product_name}
                className="img-responsive"
                style={{height:"220px"}}
              />
            </div>
            {product.labels &&
              product.labels.map((label, index) => (
                <span
                  key={index}
                  className={`label label-${label.toLowerCase()}`}
                >
                  {product.discount}% OFF
                </span>
              ))}
          </Link>
        </div>

        <div
          className={`right-block ${
            viewMode === "list" ? "col-md-8" : ""
          }`}
        >
          <div className="caption">
            <h4>
            <p>{product.product_name.length > 24
                  ? `${product.product_name.slice(0, 24)}...`
                : product.product_name}</p>
            </h4>

            <div className="responsive-mobile">
              <div className="price">
                <span className="price-new">
                  <i className="bi bi-currency-rupee"></i>
                  {parseFloat(product.online_rate).toFixed(2)}
                </span>
                {product.mrp_rate && product.mrp_rate > 0 && (
                  <span className="price-old">
                    <i className="bi bi-currency-rupee"></i>
                    {parseFloat(product.mrp_rate).toFixed(2)}
                  </span>
                )}
                &nbsp;
                {product.discount > 0 && (
                  <span className="label label-percent">
                    {product.discount}%
                  </span>
                )}
              </div>

              <div
                className={`description item-desc ${
                  viewMode === "grid" ? "hidden" : ""
                }`}
              >
                <p>{product.full_description}</p>
              </div>

              <div className="button-group">
                <button
                  className="addToCart"
                  type="button"
                  title="Add to Cart"
                  onClick={() => addToCart(product)}
                >
                  <i className="fa fa-shopping-cart" />
                  <span className="">Add to Cart</span>
                </button>
                <button
                  className="wishlist"
                  type="button"
                  title="Add to Wish List"
                  onClick={() => handleAddToWishlist(product)}
                >
                  <i className="fa fa-heart" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
) : (
  <div>No products available</div>
)}

      </div>
    
                </div>
                {products.length > 9 && (
                        <div className="product-filter product-filter-bottom filters-panel">
                          <div className="row">
                            <div className="col-md-2 hidden-sm hidden-xs"></div>
                            <div className="short-by-show text-center col-md-7 col-sm-8 col-xs-12">
                              <div className="form-group" style={{ margin: "7px 10px" }}>
                                Showing {indexOfFirstProduct + 1} to {indexOfLastProduct} of {products.length} (Page {currentPage} of {totalPages})
                              </div>
                            </div>
                            <div className="box-pagination col-md-3 col-sm-4 text-right">
                              <ul className="pagination">
                                <li className={currentPage === 1 ? "disabled" : ""}>
                                  <span onClick={() => handlePageChange(1)}>&lt;|</span>
                                </li>
                                <li className={currentPage === 1 ? "disabled" : ""}>
                                  <span onClick={() => handlePageChange(currentPage - 1)}>&lt;</span>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => (
                                  <li
                                    key={index + 1}
                                    className={currentPage === index + 1 ? "active" : ""}
                                  >
                                    <span onClick={() => handlePageChange(index + 1)}>{index + 1}</span>
                                  </li>
                                ))}
                                <li className={currentPage === totalPages ? "disabled" : ""}>
                                  <span onClick={() => handlePageChange(currentPage + 1)}>&gt;</span>
                                </li>
                                <li className={currentPage === totalPages ? "disabled" : ""}>
                                  <span onClick={() => handlePageChange(totalPages)}>&gt;|</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}

              </div>
            </div>
            {/* <!--Middle Part End--> */}
          </div>
        </div>
      </div>
      <Footer />

      {/* Offcanvas for smaller screens */}
      <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} placement="start">
        <Offcanvas.Header >
          
          <Offcanvas.Title>Our Brands</Offcanvas.Title>
          <Button
            variant="link"
            className="text-muted"
            style={{ fontSize: '24px', marginLeft: 'auto' }}
            onClick={handleOffcanvasToggle}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="box-category">
            <ul className="list-group">
            {categories
                  .slice(0, categories.length) // Show all categories
                  .map((category) => {
                    const brandName = category.brand_name || "Unknown Brand"; // Fallback for undefined
                    return (
                      <li
                        key={category.subcategory_id}
                        className="hadchild"
                        style={{cursor:"pointer"}}
                        onClick={() => handleCategoryClick(brandName)}
                      >
                        {brandName}
                      </li>
                    );
                  })}
              <li
                className="loadmore"
                onClick={toggleCategories}
                style={{ fontWeight: "bold" }}
              >
                <i className="fa fa-plus-square-o"></i>
                <span
                  className="more-view"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                  {showAllCategories ? "Show Less" : "More Categories"}
                </span>
              </li>
            </ul>
          </div>
          <div style={{ width: "200px", margin: "20px auto", textAlign: "center" }}>
          <h3>Price Filter</h3>
        <p>
          Min: <i className="bi bi-currency-rupee"></i>
          {range[0]} - Max: <i className="bi bi-currency-rupee"></i>
          {range[1]}
        </p>
        <Slider
          range
          min={0}
          max={100000}
          step={10}
          defaultValue={[0, 100000]}
          value={range}
          onChange={handleSliderChange}
          trackStyle={[{ backgroundColor: "#FF9900" }]}
          handleStyle={[
            { backgroundColor: "#FF9900", border: "none" },
            { backgroundColor: "#FF9900", border: "none" },
          ]}
        />
        <button
          onClick={handleFilter}
          style={{
            marginTop: "20px",
            backgroundColor: "#FF9900",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Go
        </button>
      </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
      )}
      </>
  );
};

export default Shop;
