import React, { useState, useEffect, useContext } from "react";
import Header from "../Components/Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css"; // Ensure correct CSS path
import "owl.carousel/dist/assets/owl.theme.default.css"; // Ensure correct CSS path
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import { Link } from "react-router-dom";
import { CartContext } from "../Context/CartContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaShoppingCart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import img1 from "../Assets/paper-plate-raw-materials-250x250.webp";
import img2 from "../Assets/2023-09-07-at-15-11-55-paper-a4-250x250.webp";
import img3 from "../Assets/2023-09-21-at-13-03-31-bio-food-grade-paper-leaf-250x250.webp";
import img4 from "../Assets/finger-chips-box-250x250.webp";
import img5 from "../Assets/paper-shopping-bag-250x250.webp";
import img6 from "../Assets/2023-09-07-at-15-11-55-paper-a4-250x250.webp";
import samplevideo from '../Assets/1751677052.mp4';
import Loader from '../Components/Loader';
    

const Home = () => {
  const [activeCategory, setActiveCategory] = useState("20");
  const [hotSale, setHotSale] = useState([]);
  const [mostviewed, setMostViewed] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState({});
  const [newproduct, setNewProduct] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Function to check screen size
  const { addItemToCart, setCartItems } = useContext(CartContext);
  const [subbanner, setSubBanner] = useState([]);
  const [shuffledBanners, setShuffledBanners] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [visibleBanners, setVisibleBanners] = useState([]);
  const [message, setMessage] = useState("");
  const [wishlistmessage, setWishListMessage] = useState("");
  const[ mediaFiles ,setMediaFiles] = useState ([])
  const [isLoading, setIsLoading] = useState(true);
  
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    // Check the screen size on initial load
    checkScreenSize();

    // Add an event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    fetch("https://unitedmart.in/customerapp/home.php", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.head.code === 200) {
          // const apiCategories = data.body.category_list.map((cat, index) => ({
          //   id: `${index + 1}`,
          //   name: cat.category_name,
          // }));
          // const apiCategories = [
          //   { id: "1", name: "Electronics" },
          //   { id: "2", name: "Home Appliance" },
          //   { id: "3", name: "Kitchen Appliance" },
          //   { id: "4", name: "Accessories" },
          // ];
          // const apiProducts = {
          //   1: data.body.featured.electronics || [],
          //   2: data.body.featured.home || [],
          //   3: data.body.featured.kitchen || [],
          //   4: data.body.featured.accessories || [],
          // };

          // const newProducts = {
          //   1: data.body.new.electronics || [],
          //   2: data.body.new.home || [],
          //   3: data.body.new.kitchen || [],
          //   4: data.body.new.accessories || [],
          // };
          const products = data.body.category_list || [];
          const newProducts = data.body.new || [];
          const hotProducts = data.body.hot || [];
          const media = data.body.product_video || [];
          // const Mostviewed = data.body.most_view || [];
          // const subBanners = data.body.sub_banner || [];
          // setCategories(apiCategories);
          setProducts(products);
          console.log(products)
          setNewProduct(newProducts);
          console.log(newProducts)
          setMediaFiles(media)
          console.log(media)
          // setSubBanner(subBanners);
          // setShuffledBanners(subBanners);
          setHotSale(hotProducts);
          // setMostViewed(Mostviewed);
          setActiveCategory("1");
        }
      })
      .catch((error) => console.error("Error fetching API:", error));
  }, []);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
  };

  const handleAddToCart = (product) => {
    setCartItems((prevCartItems) => {
      const existingProduct = prevCartItems.find(
        (item) => item.product_id === product.product_id
      );
  
      if (existingProduct) {
        // If product exists, increase quantity
        return prevCartItems.map((item) =>
          item.product_id === product.product_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
  
      // If product does not exist, add it with qty from product
      return [
        ...prevCartItems,
        {
          ...product,
        onlinerate: product.online_rate,
        qty: Number(product.qty), // Ensure it's a number
        tax_amt: product.tax_amt,
        minimumquantity: Number(product.qty),  // Keeping this for reference
        },
      ];
    });
  
    setMessage("Item Added To Cart!");
    setTimeout(() => {
      setMessage("");
    }, 2500);
  };
  

  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWishlist(storedWishlist);
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    console.log("USERID", localStorage.getItem("user_id"));
    if (userId) {
      const storedWishlist =
        JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
      setWishlist(storedWishlist);
    }
  }, []);


  //--------------Add product to wishlist---------------------//
  
  const handleAddToWishlist = (product) => {
    const userId = localStorage.getItem("user_id");
    console.log("USERID", userId);
  
    if (!userId) {
      alert("Please log in to add items to your wishlist.");
      return;
    }
  
    const storedWishlist =
      JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
  
    const isAlreadyInWishlist = storedWishlist.some(
      (item) => item.product_id === product.product_id
    );
  
    if (!isAlreadyInWishlist) {
      // Ensure the product is stored with its quantity
      const updatedProduct = { ...product, qty: product.qty || 1 }; // Default to 1 if qty is undefined
      const updatedWishlist = [...storedWishlist, updatedProduct];
  
      // Save to localStorage
      localStorage.setItem(`wishlist_${userId}`, JSON.stringify(updatedWishlist));
  
      // Update state
      setWishlist(updatedWishlist);
      alert("Product added to wishlist!");
      setWishListMessage("Item Added To Wishlist");
  
      setTimeout(() => {
        setWishListMessage("");
      }, 2500);
    } else {
      alert("Product is already in your wishlist.");
    }
  };
  

  const options = {
    loop: false,
    margin: 35,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayHoverPause: true,
    navText: [
      '<div class="custom-arrow left-arrow">‹</div>',
      '<div class="custom-arrow right-arrow">›</div>',
    ],
    responsive: {
      0: { items: 1.2 },
      600: { items: 2 },
      1000: { items: 5 },
    },
  };

  const carouselOptions = {
    loop: true,
    margin: 35,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayHoverPause: true,
    navText: [
      '<div class="custom-arrow left-arrow">‹</div>',
      '<div class="custom-arrow right-arrow">›</div>',
    ],
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  const images = [img1, img2, img3, img4, img5, img6];

  /* -------------large shuffle image------------------- */
  const shuffleBanners = () => {
    const shuffled = [...subbanner]; // Create a copy of the subbanner array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    setShuffledBanners(shuffled); // Set the shuffled banners
  };

  useEffect(() => {
    // Set an interval to shuffle the banners every 20 seconds
    const intervalId = setInterval(shuffleBanners, 20000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [subbanner]); // Re-run when subbanner changes

  // Limit the displayed banners to 3
  const bannersToDisplay = shuffledBanners.slice(0, 3);

  /* -------------large shuffle image end ------------------- */

  /* -------------small shuffle banner right side image start ------------------- */

  const shuffleAndSetBanners = () => {
    if (subbanner.length > 0) {
      const shuffled = [...subbanner].sort(() => Math.random() - 0.5);
      setVisibleBanners(shuffled.slice(0, 2)); // Pick the first two banners
    }
  };

  useEffect(() => {
    // Shuffle and set initial banners
    shuffleAndSetBanners();

    // Update banners every 10 seconds
    const intervalId = setInterval(shuffleAndSetBanners, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [subbanner]);

  /* -------------small shuffle banner right side image end ------------------- */
  // const mediaFiles = [
  //   samplevideo,
  //   samplevideo,   
  //   samplevideo,
  //   samplevideo,
  //   samplevideo,
  //   samplevideo,
  //   samplevideo,
  // ];

  useEffect(() => {
    // Simulate a network request or loading process
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
    }, []);
  
  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
        <>
      <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
          <Header />
          {message && (
            <div className="message-popup">
              <FaShoppingCart className="cart-icon-addtocart" />
              <div className="message">{message}</div>
            </div>
          )}
          {wishlistmessage && (
            <div className="wishlist-message-popup">
              <FaHeart className="heart-icon-addtowishlist" />
              <div className="wishlist-message">{wishlistmessage}</div>
            </div>
          )}
          <Banner />
          <br />
          {/* <section class="so-spotlight1 ">
           <div class="container">
              <div class="row">
                <div
                  id="yt_header_right"
                  class="col-lg-offset-3 col-lg-9 col-md-12"
                >
                  <div class="slider-container ">
                    <Banner />

                    <div class="module col-md-4  hidden-sm hidden-xs three-block ">
                      <div class="modcontent clearfix">
                        <div class="htmlcontent-block">
                        <ul className="htmlcontent-home">
                          {visibleBanners.map((banner, index) => (
                            <li key={index}>
                              <div className="banners">
                                <div>
                                  <Link
                                    to={`/products/${banner.subcategory_id}`}
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={banner.subcategory_image || "/path/to/fallback-image.jpg"} // Use subcategory_image here
                                      alt={`banner${index + 1}`}
                                      style={{ width: "100%", height: "auto" }} // Adjust styles as needed
                                    />
                                  </Link>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- //Block Spotlight1  --> */}
          <section class="so-spotlight1 ">
            <div class="container">
              <div class="row">
                <div class="banner-html col-sm-12">
                  <div class="module customhtml policy-v3">
                    <div class="modcontent clearfix">
                      <div class="block-policy-v3">
                        <div class="policy policy1 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                          <div class="policy-inner">
                            <span class="ico-policy"></span>
                            <h2>Nature Of Business</h2>
                            <a style={{ cursor: "default" }}>Retailer</a>
                          </div>
                        </div>
                        <div class="policy policy2 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                          <div class="policy-inner">
                            <span class="ico-policy"></span>
                            <a style={{ cursor: "default" }}>
                              <h2>Legal Status of Firm</h2>Proprietorship{" "}
                            </a>
                          </div>
                        </div>
                        <div class="policy policy3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                          <div class="policy-inner">
                            <span class="ico-policy"></span>
                            <a style={{ cursor: "default" }}>
                              <h2>Total Number of Employees</h2>26 to 50 People{" "}
                            </a>
                          </div>
                        </div>
                        <div class="policy policy4 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                          <div class="policy-inner">
                            <span class="ico-policy"></span>
                            <a style={{ cursor: "default" }}>
                              <h2>GST Number</h2>33AQJPK1021C1ZN{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="main-container container">
            <div class="row">
              <div id="content" class="col-sm-12">
                <div className="module tab-slider titleLine">
                  <h3 className="modtitle">Our Categories</h3>
                  <div className="so-listing-tabs first-load module">
                    <div className="ltabs-wrap">
                      <div className="ltabs-tabs-container">
                        <div
                          className={`ltabs-tabs-wrap ${
                            isSmallScreen ? "ltabs-selectbox" : ""
                          } `}
                        >
                          {/*  <span className="ltabs-tab-selected owl-carousel">
                            {
                              categories.find(
                                (cat) => cat.id === activeCategory
                              )?.name
                            }
                          </span> */}

                          {/* {isSmallScreen && (
                            <span
                              className="ltabs-tab-arrow"
                              onClick={toggleDropdown}
                            >
                              ▼
                            </span>
                          )} */}

                          {/*<div className="item-sub-cat">
                             <ul
                              className={`ltabs-tabs cf ${
                                isSmallScreen && isDropdownOpen
                                  ? "ltabs-open"
                                  : ""
                              }`}
                            >
                              {categories.map((category) => (
                                <li
                                  key={category.id}
                                  className={`ltabs-tab ${
                                    activeCategory === category.id
                                      ? "tab-sel"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleCategoryClick(category.id)
                                  }
                                >
                                  <span className="ltabs-tab-label">
                                    {category.name}
                                  </span>
                                </li>
                              ))}
                            </ul> 
                          </div>*/}
                        </div>
                      </div>
                      {/* Products */}
                      <div className="ltabs-items-container">
                        {Object.keys(products).map((categoryId) => (
                          <div
                            key={categoryId}
                            className={`ltabs-items ${
                              activeCategory === categoryId
                                ? "ltabs-items-selected"
                                : "ltabs-items-hidden"
                            }`}
                          >
                            <div
                              class="ltabs-items ltabs-items-selected items-category-20 grid"
                              data-total="10"
                            >
                              <div className="ltabs-items-inner ltabs-slider">
                              <OwlCarousel
                                  className="owl-theme"
                                  loop={true} // Ensures infinite loop
                                  margin={30}
                                  autoplay={true} // Enables automatic sliding
                                  autoplayTimeout={2000} // Adjust speed (2 seconds per slide)
                                  autoplayHoverPause={false} // Prevents pause on hover
                                  smartSpeed={1000} // Controls animation speed
                                  dots={false}
                                  nav={false} // Hides navigation arrows
                                  items={products.length} 
                                  responsive={{
                                    0: { items: 1.2 },
                                    600: { items: 2 },
                                    1000: { items: 4 },
                                  }}
                                >

                                  {products && products.length > 0 ? (
                                    products.map((product) => (
                                      <div
                                        key={product.category_id}
                                        className="ltabs-item product-layout"
                                      >
                                        <div className="product-item-container">
                                          <Link
                                            to={`/products/${product.category_id}`}
                                          >
                                            <div className="left-block">
                                              <div className="product-image-container second_img">
                                                <img
                                                  src={product.image}
                                                  alt={product.category_name}
                                                  className="img-responsive"
                                                  style={{ height: "250px" }}
                                                />
                                              </div>
                                              {product.label && (
                                                <span
                                                  className={`label label-${product.labelType}`}
                                                >
                                                  {product.label}
                                                </span>
                                              )}
                                            </div>
                                            <div className="right-block">
                                              <div className="caption">
                                                <h4>
                                                  <Link
                                                    to={`/productdetail/${product.product_id}`}
                                                  >
                                                    <p>
                                                      {product.category_name
                                                        .length > 24
                                                        ? `${product.category_name.slice(
                                                            0,
                                                            24
                                                          )}...`
                                                        : product.category_name}
                                                    </p>
                                                  </Link>
                                                </h4>
                                                {/* <div className="price">
                                                      <span className="price-new">
                                                        <i className="bi bi-currency-rupee"></i>
                                                        {product.online_rate}
                                                      </span>
                                                      {product.mrp_rate > 0 && (
                                                        <span className="price-old">
                                                          <i className="bi bi-currency-rupee"></i>
                                                          {product.mrp_rate}
                                                        </span>
                                                      )}
                                                    </div> */}
                                              </div>
                                            </div>
                                          </Link>

                                          {/* <div className="button-group">
                                             
                                                <button
                                                  className="addToCart"
                                                  type="button"
                                                  title="Add to Cart"
                                                  onClick={() => handleAddToCart(product)}
                                                >
                                                  <i className="fa fa-shopping-cart"></i>
                                                  <span>Add to Cart</span>
                                                </button>
                                                <button
                                                  className="wishlist"
                                                  type="button"
                                                  title="Add to Wish List"
                                                  onClick={() => handleAddToWishlist(product)}
                                                >
                                                  <i className="fa fa-heart"></i>
                                                </button>
                                              </div> */}
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="no-products">
                                      <p>No products available</p>
                                    </div>
                                  )}
                                </OwlCarousel>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* end featured products */}

                <div class="module ">
                  <div class="modcontent clearfix">
                    <div class="banner-wraps ">
                      <div class="m-banner row">
                        {/* {bannersToDisplay.length > 0 ? (
                          bannersToDisplay.map((banner, index) => (
                            <div
                              className="banner htmlconten1 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                              key={index}
                            >
                              <div className="banners">
                                <div>
                                  <Link
                                    to={`/products/${banner.subcategory_id}`}
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        banner.subcategory_image ||
                                        "/path/to/fallback-image.jpg"
                                      } // Use subcategory_image here
                                      alt={`banner${index + 1}`}
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No banners available</p> 
                        )} */}
                        {/* <div class="htmlconten2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                          <div class="module banners">
                            <div>
                              <a href="#">
                                <img
                                  src="assets/images/banner2-2.png"
                                  alt="banner1"
                                />
                              </a>
                            </div>
                          </div>

                          <div class="banners">
                            <div>
                              <a href="#">
                                <img
                                  src="assets/images/banner2-3.png"
                                  alt="banner1"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="banner htmlconten3 hidden-sm col-lg-4 col-md-4 col-sm-6 col-xs-12">
                          <div class="banners">
                            <div>
                              <a href="#">
                                <img
                                  src="assets/images/banner2-4.png"
                                  alt="banner1"
                                />
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* end add banner */}

                <div class="module tab-slider titleLine">
                  <h3 class="modtitle">New Arrivals</h3>
                  <div
                    id="so_listing_tabs_2"
                    class="so-listing-tabs first-load module"
                  >
                    <div class=""></div>
                    <div class="ltabs-wrap">
                      <div class="ltabs-tabs-container">
                        <div
                          className={`ltabs-tabs-wrap ${
                            isSmallScreen ? "ltabs-selectbox" : ""
                          }`}
                        >
                          {/*  <span className="ltabs-tab-selected owl-carousel">
                            {
                              categories.find(
                                (cat) => cat.id === activeCategory
                              )?.name
                            }
                          </span> */}
                          {/* {isSmallScreen && (
                            <span
                              className="ltabs-tab-arrow"
                              onClick={toggleDropdown}
                            >
                              ▼
                            </span>
                          )} */}
                          {/* <div className="item-sub-cat">
                            <ul
                              className={`ltabs-tabs cf ${
                                isSmallScreen && isDropdownOpen
                                  ? "ltabs-open"
                                  : ""
                              }`}
                            >
                              {categories.map((category) => (
                                <li
                                  key={category.id}
                                  className={`ltabs-tab ${
                                    activeCategory === category.id
                                      ? "tab-sel"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleCategoryClick(category.id)
                                  }
                                >
                                  <span className="ltabs-tab-label">
                                    {category.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div> */}
                        </div>
                        {/* <!-- End Tabs--> */}
                      </div>
                      <div className="ltabs-items-container">
                        {Object.keys(newproduct).map((categoryId) => (
                          <div
                            key={categoryId}
                            className={`ltabs-items ${
                              activeCategory === categoryId
                                ? "ltabs-items-selected"
                                : "ltabs-items-hidden"
                            }`}
                          >
                            <div
                              class="ltabs-items ltabs-items-selected items-category-20 grid"
                              data-total="10"
                            >
                              <div className="ltabs-items-inner ltabs-slider">
                                <OwlCarousel
                                  className="owl-theme"
                                  loop={true}
                                  margin={30} // Margin between cards
                                  nav={false}
                                  autoplay={true}
                                  dots={false}
                                  responsive={{
                                    0: { items: 1.2 },
                                    600: { items: 2 },
                                    1000: { items: 4 },
                                  }}
                                >
                                  {newproduct?.length > 0 ? (
                                    newproduct.map((product) => (
                                      <div
                                        key={product.id}
                                        className="ltabs-item product-layout"
                                      >
                                        <div className="product-item-container">
                                          <Link
                                            to={`/productdetail/${product.product_id}`}
                                          >
                                            <div className="left-block">
                                              <div className="product-image-container second_img">
                                                <img
                                                  src={product.image}
                                                  alt={product.name}
                                                  className="img-responsive "
                                                  style={{ height: "250px" }}
                                                />
                                              </div>
                                              {product.label && (
                                                <span
                                                  className={`label label-${product.labelType}`}
                                                >
                                                  {product.label}
                                                </span>
                                              )}
                                            </div>
                                          </Link>
                                          <div className="right-block">
                                            <div className="caption">
                                              <h4>
                                                <p>
                                                  {product.product_name.length >
                                                  24
                                                    ? `${product.product_name.slice(
                                                        0,
                                                        24
                                                      )}...`
                                                    : product.product_name}
                                                </p>
                                              </h4>
                                            </div>
                                          </div>
                                          <div className="responsive-mobile">
                                              <div className="price">
                                                <span className="price-new">
                                                  <i class="bi bi-currency-rupee"></i>
                                                  {product.online_rate}
                                                </span>
                                                {product.mrp_rate > 0 && (
                                                  <span className="price-old">
                                                    <i class="bi bi-currency-rupee"></i>
                                                    {product.mrp_rate}
                                                  </span>
                                                )}
                                              </div>
                                        <div className="button-group">
                                          <button
                                            className="addToCart"
                                            type="button"
                                            title="Add to Cart"
                                            onClick={() =>
                                              handleAddToCart(product)
                                            }
                                          >
                                            <i className="fa fa-shopping-cart"></i>
                                            <span>Add to Cart</span>
                                          </button>
                                          <button
                                            className="wishlist"
                                            type="button"
                                            title="Add to Wish List"
                                            onClick={() => handleAddToWishlist(product)}
                                          >
                                            <i className="fa fa-heart"></i>
                                          </button>
                                        </div>
                                        </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="no-products">
                                      <p>No products available</p>
                                    </div>
                                  )}
                                </OwlCarousel>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <!--End Items--> */}
                    </div>
                  </div>
                </div>
                {/* End New Products */}
              </div>
            </div>
          </div>
          <section class="so-spotlight2">
            <div class="container">
              <div class="row">
                <div class="col-xs-12">
                  <div class="module titleLine module--4x">
                    <h3 class="modtitle">Hot Sale</h3>
                    <div className="modcontent clearfix">
                      <div className="so-basic-products products-list product-style__thumb1 grid row hotsale-product">
                        {hotSale.map((product, index) => (
                          <div
                            className="ltabs-item product-layout col-md-4 col-sm-6 col-xs-12"
                            key={index}
                          >
                            <div className="product-item-container ">
                              <Link to={`/productdetail/${product.product_id}`}>
                                <div className="left-block">
                                  <a
                                    href="#"
                                    className="product-image-container"
                                    title={product.title}
                                  >
                                    <img
                                      src={product.image}
                                      alt={product.title}
                                      className="img-responsive"
                                    />
                                  </a>
                                </div>
                                <div className="right-block">
                                  <div className="caption">
                                    <h4>
                                      <p>   {product.product_name.length > 24
                                                            ? `${product.product_name.slice(0, 24)}...`
                                                            : product.product_name}</p>
                                    </h4>
                                    <div className="price">
                                      <span className="price-new">
                                        <i className="bi bi-currency-rupee"></i>
                                        {product.online_rate}
                                      </span>
                                      {product.priceOld && (
                                        <span className="price-old">
                                          <i className="bi bi-currency-rupee"></i>
                                          {product.mrp_rate}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <div className="button-group">
                                <button
                                  className="addToCart"
                                  type="button"
                                  // onClick={() => addToCart(product.id)}
                                  onClick={() => handleAddToCart(product)}
                                >
                                  <i className="fa fa-shopping-cart"></i>{" "}
                                  <span className="button-group__text">
                                    Add to Cart
                                  </span>
                                </button>
                                <button
                                  className="wishlist"
                                  type="button"
                                  onClick={() => handleAddToWishlist(product)}
                                >
                                  <i className="fa fa-heart"></i>{" "}
                                  <span className="button-group__text">
                                    Add Wish List
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="so-spotlight1">
        <div className="container">
          <div className="row">
          <div class="col-xs-12">
            <div className="module titleLine">
            <h3 class="modtitle">Product Videos</h3>
              <div className="module">
                <div
                  className="yt-content-slider yt-content-slider--arrow2"
                  data-rtl="yes"
                  data-autoplay="no"
                  data-autoheight="no"
                  data-delay="4"
                  data-speed="0.6"
                  data-margin="35"
                  data-items_column0="5"
                  data-items_column1="4"
                  data-items_column2="3"
                  data-items_column3="2"
                  data-items_column4="1"
                  data-arrows="yes"
                  data-pagination="no"
                  data-lazyload="yes"
                  data-loop="yes"
                  data-hoverpause="yes"
                >
            <OwlCarousel className="owl-theme" {...options}>
                {mediaFiles.map((file, index) => (
                  <div key={index} className="item video-item">
                  {file.video ? (
                    file.video.includes("youtube.com") || file.video.includes("youtu.be") ? (
                      <>
                        <div
                          className="video-thumbnail"
                          onClick={(e) => {
                            e.currentTarget.innerHTML = `
                              <iframe
                                src="https://www.youtube.com/embed/${new URL(file.video).searchParams.get("v")}?autoplay=1"
                                title="Video ${index + 1}"
                                width="100%"
                                height="220px"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>`;
                          }}
                        >
                          <img
                            src={`https://img.youtube.com/vi/${new URL(file.video).searchParams.get("v")}/hqdefault.jpg`}
                            alt={`Video ${index + 1}`}
                            width="100%"
                            height="220px"
                            style={{ cursor: "pointer" }}
                          />
                          <div className="play-button">
                            <i className="fa fa-play-circle"></i>
                          </div>
                        </div>
                
                        <div className="right-block">
                          <div className="caption">
                            <h4>
                              <p>
                                {file.product_name.length > 24
                                  ? `${file.product_name.slice(0, 24)}...`
                                  : file.product_name}
                              </p>
                            </h4>
                          </div>
                        </div>
                      </>
                    ) : (
                      <video
                        src={file.video}
                        controls
                        width="100%"
                        height="220px"
                        title={`Video ${index + 1}`}
                      />
                    )
                  ) : (
                    <a href="#" title={`Image ${index + 1}`}>
                      <img src={file.video} alt={`Image ${index + 1}`} />
                    </a>
                  )}
                </div>
                
                                  ))}
                  </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



          {/* <section class="so-spotlight2">
            <div class="container">
              <div class="row">
                <div class="col-xs-12">
                  <div class="module titleLine module--4x">
                    <h3 class="modtitle">Most Viewed</h3>
                    <div className="modcontent clearfix">
                      <div className="so-basic-products products-list product-style__thumb1 grid row ">
                      <OwlCarousel className="owl-theme" {...carouselOptions}>
      {mostviewed.map((product, index) => (
        <div
          className="ltabs-item product-layout item"
          key={index}
        >
          <div className="product-item-container">
            <Link to={`/productdetail/${product.product_id}`}>
              <div className="left-block">
                <div className="product-image-container" title={product.title}>
                  <img
                    src={product.image}
                    alt={product.title}
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="right-block">
                <div className="caption">
                  <h4>
                    <p>   {product.product_name.length > 24
                          ? `${product.product_name.slice(0, 24)}...`
                          : product.product_name}</p>
                  </h4>
                  <div className="price">
                    <span className="price-new">
                      <i className="bi bi-currency-rupee"></i>
                      {product.online_rate}
                    </span>
                    {product.mrp_rate > 0 && (
                      <span className="price-old">
                        <i className="bi bi-currency-rupee"></i>
                        {product.mrp_rate}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Link>
            <div className="button-group">
              <button
                className="addToCart"
                type="button"
                onClick={() => handleAddToCart(product)}
              >
                <i className="fa fa-shopping-cart"></i>{" "}
                <span className="button-group__text">Add to Cart</span>
              </button>
              <button
                className="wishlist"
                type="button"
                onClick={() => handleAddToWishlist(product)}
              >
                <i className="fa fa-heart"></i>{" "}
                <span className="button-group__text">Add Wish List</span>
              </button>
            </div>
          </div>
        </div>
      ))}
    </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
      <Footer />
      </>
      )}
    </>
  );
};
export default Home;
