import React, { createContext, useState, useEffect } from "react";

// Create the context
const CartContext = createContext();

// Create a provider component
const CartProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => localStorage.getItem("user_id"));
  const [cartItems, setCartItems] = useState(() => {
    const storedUserId = localStorage.getItem("user_id");
    const storedCartItems = localStorage.getItem(`cartItems_${storedUserId}`);
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [cartCount, setCartCount] = useState(0);

  // Save cart items to localStorage whenever they change
  useEffect(() => {
    if (userId) {
      localStorage.setItem(`cartItems_${userId}`, JSON.stringify(cartItems));
    }
  }, [cartItems, userId]);

  // Reload cart items when user ID changes
  useEffect(() => {
    if (userId) {
      const storedCartItems = localStorage.getItem(`cartItems_${userId}`);
      setCartItems(storedCartItems ? JSON.parse(storedCartItems) : []);
    }
  }, [userId]);

  // Function to add an item to the cart
  const addItemToCart = (item) => {
    const onlinerate = Number(item.onlinerate);
    const taxAmt = Number(item.tax_amt);
    const quantity = Number(item.qty) || 1;
    if (item.stock <= 0) {
      alert('Product is out of stock.');
      return;
    }
  
    const itemExists = cartItems.find(
      (cartItem) =>
        cartItem.product_id === item.product_id && cartItem.image === item.image
    );
  
    if (!itemExists) {
      setCartItems([
        ...cartItems,
        {
          ...item,
          onlinerate,
          qty: quantity, // Default quantity to 1
          tax_amt: taxAmt,
          tot: onlinerate * 1 + taxAmt,
        },
      ]);
    } else {
      const newQty = itemExists.qty + 1;
      if (newQty > item.stock) {
        alert('Stock limit reached!');
        return;
      }
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id && cartItem.image === item.image
            ? {
                ...cartItem,
                qty: newQty,
                tot: onlinerate * newQty + taxAmt,
              }
            : cartItem
        )
      );
    }
  };
  

  // Function to update item quantity
  const updateItemQuantity = (product_id, image, qty) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (item.product_id === product_id && item.image === image) {
          if (qty > item.stock) {
            alert('Stock limit reached!');
            return item; // Return the item without changes
          } else if (item.stock === 0) {
            alert('Product is out of stock.');
            return item; // Return the item without changes
          }
  
          const onlinerate = Number(item.onlinerate);
          const taxAmt = Number(item.tax_amt);
          return { ...item, qty, tot: onlinerate * qty + taxAmt };
        }
        return item;
      })
    );
  };
  

  // Function to remove an item from the cart based on both product_id and image
  const removeItemFromCart = (product_id, image) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) => !(item.product_id === product_id && item.image === image)
      )
    );
  };

  // Update cart count whenever cartItems change
  useEffect(() => {
    setCartCount(cartItems.length); // Count unique products in the cart
  }, [cartItems]);

  // Function to handle user logout
  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("name");
    console.log(localStorage.removeItem("user_id"))
    localStorage.removeItem(`cartItems_${userId}`); // Clear cart for this user
    setUserId(null); // Reset user ID
    setCartItems([]); // Clear cart items in state
    window.location.href = '/beta';

  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartCount,
        addItemToCart,
        removeItemFromCart,
        setCartItems,
        updateItemQuantity,
        handleLogout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
