import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import { FiX } from "react-icons/fi";
import Loader from '../Components/Loader';


const OrderConfirmation = () => {
  const { order_id } = useParams();
	
	const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request or loading process
    const timer = setTimeout(() => setIsLoading(false), 3000);
    return () => clearTimeout(timer);
     }, []);
  
	useEffect(() => {
	  // Fetching order data
	  axios
		.post("https://unitedmart.in/customerapp/order_detail_history.php", {
		  order_id: order_id, // Example input
		})
		.then((response) => {
		  if (response.data.head.code === 200) {
			setOrderData(response.data.body);
      console.log("orderdata" , response.data.body)
		  } else {
			console.error("Error fetching data:", response.data.head.msg);
		  }
		})
		.catch((error) => console.error("API Error:", error));
	}, []);
  
	if (!orderData) {
	  return <p> </p>;
	}
  
	const { address, order_information, order_detail_history } = orderData;

  const cancelOrder = async (orderNo) => {
    try {
      const response = await axios.post("https://unitedmart.in/customerapp/cancel_product.php", {
        order_no: orderNo,
      });
      if (response.data.head && response.data.head.code === 200) {
        alert("Order canceled successfully!");
        window.location.reload();
        return true; // Success
        

      } else {
        alert("Failed to cancel the order. Please try again.");
        return false; // Failure
      }
    } catch (error) {
      console.error("Error canceling order:", error);
      alert("An error occurred while canceling the order.");
      return false;
    }
  };
 

  return (
    <>
    {/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
    <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
            <Header />
            <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="#"><i class="fa fa-home"></i></a></li>
			<li><a href="#">Order Infomation</a></li>
		</ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
			<div id="content" class="col-sm-12">
				<h2 class="title">Order Information</h2>

				 <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <td colSpan="2" className="text-left">Order Details</td>
          </tr>
        </thead>
        <tbody>
          {order_information.map((order) => (
            <tr key={order.order_id}>
              <td style={{ width: "50%" }} className="text-left">
                <b>Order ID:</b> #{order.order_id}
                <br />
                <b>Date Added:</b> {order.orderdate}
              </td>
              <td style={{ width: "50%" }} className="text-left">
                <b>Payment Method:</b> {order.payment_method}
                <br />
                <b>Travels Name:</b> {order.travels}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Address Table */}
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <td className="text-left">Payment Address</td>
            <td className="text-left">Shipping Address</td>
          </tr>
        </thead>
        <tbody>
          {address.map((addr, index) => (
            <tr key={index}>
              <td className="text-left">
                {addr.billing_name}
                <br />
                {addr.billing_address}, {addr.billing_city}, {addr.billing_state}
                <br />
                {addr.billing_pincode}
                <br />
                {addr.billing_mobile}
                <br />
                {addr.billing_email}
              </td>
              <td className="text-left">
                {addr.delivery_name}
                <br />
                {addr.delivery_address}, {addr.delivery_city}, {addr.delivery_state}
                <br />
                {addr.delivery_pincode}
                <br />
                {addr.delivery_mobile}
                <br />
                {addr.delivery_email}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Order Items Table */}
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
            <td className="text-left">Order No</td>
              <td className="text-left">Product Name</td>
              <td className="text-right">Quantity</td>
              <td className="text-right">Price</td>
              <td className="text-right">Total</td>
              <td className="text-right" >Order Cancel</td>
             
            </tr>
          </thead>
          <tbody>
            {order_detail_history.map((item, index) => (
              <tr key={index}>
                 <td className="text-left">{item.order_id}-{item.order_no}</td>
                <td className="text-left">{item.product_name}</td>
                <td className="text-right">{item.qty}</td>
                <td className="text-right"><i className="bi bi-currency-rupee"></i>{item.amount}</td>
                <td className="text-right"><i className="bi bi-currency-rupee"></i>{item.totalamount}</td>
                <td className="text-right">
                {item.cancel === "0" ? (
          // Show the cancel button if orderstatus is 0
          <>
          <span
            className="btn btn-primary"
            title="Cancel Order"
            style={{ marginRight: "10px" }}
            onClick={async () => {
              const confirmed = window.confirm(
                `Are you sure you want to cancel order #${item.order_no}?`
              );
              if (confirmed) {
                const success = await cancelOrder(item.order_no);
                if (success) {
                  alert(`Order #${item.order_no} has been canceled.`);
                  // Optionally update the UI or state to reflect the canceled status
                }
              }
            }}
          >
            <FiX  color="red" />
          </span>
        
                   {/* <Link to="/return" className="btn btn-danger" title="Return">
                    <i className="fa fa-reply"></i>
                  </Link> */}
                  </>
                  ) : (
                    // Show 'Cancelled' text if orderstatus is 1
                    <span className="text-primary">Cancelled</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4"></td>
              <td className="text-right"><b>Sub-Total</b></td>
              <td className="text-right"><i className="bi bi-currency-rupee"></i><b>{order_information[0].sub_total}</b></td>
            </tr>
            <tr>
              <td colSpan="4"></td>
              <td className="text-right"><b>CGST</b></td>
              <td className="text-right"><i className="bi bi-currency-rupee"></i>{order_information[0].cgst_amt}</td>
            </tr>
            <tr>
              <td colSpan="4"></td>
              <td className="text-right"><b>SGST</b></td>
              <td className="text-right"><i className="bi bi-currency-rupee"></i>{order_information[0].sgst_amt}</td>
            </tr>
            <tr>
              <td colSpan="4"></td>
              <td className="text-right"><b>Total</b></td>
              <td className="text-right"><i className="bi bi-currency-rupee"></i><b>{order_information[0].total}</b></td>
            </tr>
          </tfoot>
        </table>
				</div>
				{/* <h3>Order History</h3>
				<table class="table table-bordered table-hover">
					<thead>
						<tr>
              <td class="text-left">Order No</td>
							<td class="text-left">Date Added</td>
							<td class="text-left">Status</td>
						</tr>
					</thead>
					<tbody>
						<tr>
              <td></td>
							<td class="text-left">20/06/2016</td>
							<td class="text-left">Processing</td>
						</tr>
						<tr>
            <td></td>
							<td class="text-left">21/06/2016</td>
							<td class="text-left">Shipped</td>
						</tr>
						<tr>
            <td></td>
							<td class="text-left">24/06/2016</td>
							<td class="text-left">Complete</td>
						</tr>
					</tbody>
				</table> */}
				{/* <div class="buttons clearfix">
					<div class="pull-right"><a class="btn btn-primary" href="#">Continue</a>
					</div>
				</div> */}



			</div>
			{/* <!--Middle Part End-->
			
			<!--Right Part End --> */}
		</div>
	</div>
        </div>
    </div>
    <Footer />
    {/* </>
      )} */}
    </>
  )
}

export default OrderConfirmation