import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
	const currentYear = new Date().getFullYear();
	const [footerData, setFooterData] = useState([]);
	const [adminData, setAdminData] = useState({});
  
	useEffect(() => {
	  // Fetch API data
	  const fetchFooterData = async () => {
		try {
		  const response = await fetch('https://unitedmart.in/customerapp/footer.php', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
		  });
  
		  const data = await response.json();
		  if (data.head.code === 200) {
			setFooterData(data.body.subcategory || []);
			setAdminData(data.body.admin_data || {});
		  }
		} catch (error) {
		  console.error('Error fetching footer data:', error);
		}
	  };
  
	  fetchFooterData();
	}, []);

  // Show button when page is scrolled down
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
    <div class="footer-container type_footer1">
		{/* <!-- Footer Top Container --> */}
		<section class="footer-top">
			<div class="container content">
				<div class="row">
					<div class="col-sm-6 col-md-3 box-information">
						<div class="module clearfix">
							<h3 class="modtitle">Information</h3>
							<div class="modcontent">
								<ul class="menu">
									<li><Link to="/aboutus">About Us</Link></li>
									<li><Link to="/privacypolicy">Privacy Policy</Link></li>
									<li><Link to="/terms">Terms & Conditions</Link></li>

									{/* <li><a href="faq.html">FAQ</a></li> */}
									{/* <li><a href="order-information.html">Order information</a></li> */}
								</ul>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-md-3 box-service">
						<div class="module clearfix">
							<h3 class="modtitle">Customer Service</h3>
							<div class="modcontent">
								<ul class="menu">
									<li><Link to="/contact">Contact Us</Link></li>
									<li><Link to = "/orderhistory">Order history</Link></li>
                                    {/* <li><a href="return.html">Returns</a></li>
									<li><a href="sitemap.html">Site Map</a></li> */}
									{/* <li><Link to = '/myaccount'>My Account</Link></li> */}
								</ul>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-md-3 box-account">
						<div class="module clearfix">
							<h3 class="modtitle">My Account</h3>
							<div class="modcontent">
								<ul class="menu">
								<li><Link to = '/myaccount'>My Account</Link></li>
								<li><Link to = '/register'>Register</Link></li>
								<li><Link to = '/login'>Log In</Link></li>


									{/* <li><a href="#">Brands</a></li>
									<li><a href="gift-voucher.html">Gift Vouchers</a></li>
									<li><a href="#">Affiliates</a></li>
									<li><a href="#">Specials</a></li>
									<li><a href="#" target="_blank">Our Blog</a></li> */}
								</ul>
							</div>
						</div>
					</div>

					<div class="col-sm-6 col-md-3 collapsed-block ">
						<div class="module clearfix">
							<h3 class="modtitle">Contact Us	</h3>
							<div class="modcontent">
								<ul class="contact-address">
									<li><span class="fa fa-map-marker"></span>  {adminData.address} <br />{adminData.city} <br />{adminData.state}-{adminData.pincode}</li>
									<li><span class="fa fa-envelope-o"></span> Email: <a href="#"> {adminData.email} </a></li>
									<li><span class="fa fa-phone">&nbsp;</span> Phone:  {adminData.phonenumber} <br />whatsapp: {adminData.wphonenumber}</li>
								</ul>
							</div>
						</div>
					</div>

					{/* <div class="col-sm-12 collapsed-block footer-links">
						<div class="module clearfix">
							<div class="modcontent">
								<hr class="footer-lines" />
								<div class="footer-directory-title">
									<h4 class="label-link">Top Stores : Brand Directory | Store Directory</h4>
									<ul className="footer-directory">
											{footerData.map((category) => (
												<li key={category.id}>
												<h4>{category.sub_name}:</h4>
												{category.brand_name.length > 0 ? (
													category.brand_name.map((brand) => (
													<React.Fragment key={brand.id}>
														<a>{brand.brand_name}</a>
														<span className="word-gap"> | </span>
													</React.Fragment>
													))
												) : (
													<span>No brands available</span>
												)}
												
												</li>
											))}
											</ul>
											</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</section>
		{/* <!-- /Footer Top Container -->
		
		<!-- Footer Bottom Container --> */}
		<div class="footer-bottom-block ">
			<div class=" container">
				<div class="row">
					<div class="col-sm-5 copyright-text"> © {currentYear} Unitedmart.in. All Rights Reserved. </div>
						<div class="col-sm-7">
						<div class="block-payment text-right"> Developed By <a href="https://www.aatheshsoft.com" target="_blank" style={{color:"white"}}>Aathesh Soft Solutions</a></div>
					</div>
				{/* 	<!--Back To Top--> */}
                <div>
					{isVisible && (
						<div className="back-to-top" onClick={scrollToTop}>
						<i className="fa fa-angle-up"></i> 
						<span>Top</span>
						</div>
					)}
					</div>

				</div>
			</div>
		</div>
		{/* <!-- /Footer Bottom Container --> */}
		
		
	</div>
	


	<>
	<section class="social-widgets visible-lg">
	<ul class="items">
		<li class="item item-01 facebook"> <a href="php/facebook.php?account=envato" class="tab-icon"><span class="fa fa-facebook"></span></a>
			<div class="tab-content">
				<div class="title">
					<h5>FACEBOOK</h5>
				</div>
				<div class="loading">
					<img src="image/theme/lazy-loader.gif" class="ajaxloader" alt="loader" />
				</div>
			</div>
		</li>
		<li class="item item-02 twitter"> <a href="php/twitter.php?account_twitter=envato" class="tab-icon"><span class="fa fa-twitter"></span></a>
			<div class="tab-content">
				<div class="title">
					<h5>TWITTER FEEDS</h5> 
				</div>
				<div class="loading">
					<img src="image/theme/lazy-loader.gif" class="ajaxloader" alt="loader" />
				</div>
			</div>
		</li>
		<li class="item item-03 youtube"> <a href="php/youtubevideo.php?account_video=PY2RLgTmiZY" class="tab-icon"><span class="fa fa-youtube"></span></a>
			<div class="tab-content">
				<div class="title">
					<h5>YouTube</h5>
				</div>
				<div class="loading"> <img src="image/theme/lazy-loader.gif" class="ajaxloader" alt="loader" /></div>
			</div>
		</li>
	</ul>
</section>
</>
</>
  )
}

export default Footer