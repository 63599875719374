import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Loader from '../Components/Loader';
import { useNavigate } from 'react-router-dom';


const OrderHistory = () => {
	const [orderHistory, setOrderHistory] = useState([]);
	const userId = localStorage.getItem('user_id');
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 15;
  	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

  
	useEffect(() => {
	  if (!userId) {
		navigate('/login'); // Redirect to login if user is not logged in
	  }
	}, [userId, navigate]);
	
	// Calculate total pages
	const totalPages = Math.ceil(orderHistory.length / itemsPerPage);
  
	// Get current page data
	const currentOrders = orderHistory.slice(
	  (currentPage - 1) * itemsPerPage,
	  currentPage * itemsPerPage
	);
  
	// Handle pagination change
	const handlePageChange = (page) => {
	  setCurrentPage(page);
	};
	useEffect(() => {
		// Fetch the order history data from the API
		const fetchOrderHistory = async () => {
		  try {
			const response = await axios.post("https://unitedmart.in/customerapp/order_history.php", {
			  user_id: userId
			});
	
			if (response.data.head.code === 200) {
			  setOrderHistory(response.data.body.order_history);
			  console.log(response.data.body.order_history);
			} else {
			  console.error("Failed to fetch order history");
			}
		  } catch (error) {
			console.error("Error fetching order history:", error);
		  }
		};
	
		fetchOrderHistory();
	  }, []);


	useEffect(() => {
	  window.scrollTo(0, 0);
	}, []);

  useEffect(() => {
	// Simulate a network request or loading process
	const timer = setTimeout(() => setIsLoading(false), 3000);
	return () => clearTimeout(timer);
  }, []);

  return (
	<>
		{/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
    <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
            <Header />
            <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="#"><i class="fa fa-home"></i></a></li>
			<li><a href="#">Order History</a></li>
		</ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
			<div id="content" class="col-sm-12">
				<h2 class="title">Order History</h2>
				<div class="table-responsive">
				<table className="table table-bordered table-hover">
						<thead>
						<tr>
							<td className="text-center">Order ID</td>
							<td className="text-center">Date Added</td>
							<td className="text-center">Product Quantity</td>
							<td className="text-center">Status</td>
							
							<td className="text-right">Total</td>
							<td className="text-center">Order View</td>
						</tr>
						</thead>
						<tbody>
							{currentOrders.map((order) => (
								<tr key={order.order_id}>
								<td className="text-center">{`#${order.order_id}`}</td>
								<td className="text-center">{order.date}</td>
								<td className="text-center">{order.qty}</td>
								<td className="text-center">
									{order.status === "0" ? "Placed" : "Delivered"}
								</td>
								<td className="text-right">
									<i className="bi bi-currency-rupee"></i>  
									{parseFloat(order.total.replace(/,/g, '')).toFixed(2)}

								</td>
								<td className="text-center">
									<Link
									to={`/orderconfirmation/${order.order_id}`} // Passing the order ID
									className="btn btn-info"
									title="View"
									>
									<i className="fa fa-eye"></i>
									</Link>
								</td>
								</tr>
							))}
							</tbody>

					</table>
					{orderHistory.length > itemsPerPage && (
							<div className="pagination">
								<button
								className="btn btn-primary"
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
								>
								Previous
								</button>

								{[...Array(totalPages)].map((_, index) => (
								<button
									key={index}
									className={`btn ${currentPage === index + 1 ? "btn-secondary" : "btn-light"}`}
									onClick={() => handlePageChange(index + 1)}
								>
									{index + 1}
								</button>
								))}

								<button
								className="btn btn-primary"
								disabled={currentPage === totalPages}
								onClick={() => handlePageChange(currentPage + 1)}
								>
								Next
								</button>
							</div>
							)}


				</div>

			</div>
			{/* <!--Middle Part End--> */}
		
		</div>
	</div>
</div>
</div>
<Footer />
{/* </>
	  )} */}
</>
  )
}

export default OrderHistory