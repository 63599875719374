import React,{useState,useContext,useEffect} from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import {  Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../Context/CartContext';
import Loader from '../Components/Loader';

const Cart = () => {
	const [quantity, setQuantity] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const { cartItems,updateItemQuantity, removeItemFromCart } =  useContext(CartContext);
	const userId = localStorage.getItem('user_id');
	const navigate = useNavigate();

  
	useEffect(() => {
	  if (!userId) {
		navigate('/login'); // Redirect to login if user is not logged in
	  }
	}, [userId, navigate]);

	
  const handleCheckout = () => {
    const userId = localStorage.getItem("user_id");
    if (!userId || userId === "0") {
      navigate("/login"); // Redirect to the login page
    } else {
      navigate("/checkout"); // Redirect to the checkout page
    }
  };

  const handleIncrease = (product_id, image, currentQty) => {
	const product = cartItems.find(
	  (item) => item.product_id === product_id && item.image === image
	);
  
	if (product) {
	  if (currentQty + 1 > product.stock) {
		alert('Stock limit reached!');
		return;
	  }
	  updateItemQuantity(product_id, image, currentQty + 1);
	}
  };
  
  const handleDecrease = (product_id, image, currentQty) => {
	const product = cartItems.find(
	  (item) => item.product_id === product_id && item.image === image
	);
  
	if (product) {
	  const minQuantity = product.minimumquantity ?? 1; // Set minimum quantity from product data
  
	  if (currentQty > minQuantity) {
		const newQty = currentQty - 1;
		setQuantity(newQty);
		updateItemQuantity(product_id, image, newQty);
	  } else {
		alert(`Minimum quantity is ${minQuantity}.`);
	  }
	}
  };
console.log(cartItems)

// const subtotal = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + item.onlinerate * item.qty : acc),
// 	0
//   );
  
//   const cgst = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + (item.tax_amt / 2) * item.qty : acc),
// 	0
//   );
  
//   const sgst = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + (item.tax_amt / 2) * item.qty : acc),
// 	0
//   );
const subtotal = cartItems.reduce(
	(acc, item) => acc + item.onlinerate * item.qty,
	0
  );
  
  const cgst = cartItems.reduce(
	(acc, item) => acc + (item.tax_amt / 2) * item.qty,
	0
  );
  
  const sgst = cartItems.reduce(
	(acc, item) => acc + (item.tax_amt / 2) * item.qty,
	0
  );
  
  const total = subtotal + cgst + sgst;

  useEffect(() => {
	// Simulate a network request or loading process
	const timer = setTimeout(() => setIsLoading(false), 3000);
	return () => clearTimeout(timer);
  }, []);

  return (
    <>
	{/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
<div class="common-home res layout-home1">
    <div id="wrapper" class="wrapper-full banners-effect-7">
        <Header />
        <div class="main-container container">
		    <ul class="breadcrumb">
			    <li><a href="#"><i class="fa fa-home"></i></a></li>
			    <li><a href="#">Shopping Cart</a></li>
		    </ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
        <div id="content" class="col-sm-12">
          <h2 class="title">Shopping Cart</h2>
            <div class="table-responsive form-group">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <td class="text-center">Image</td>
                    <td class="text-left">Product Name</td>
                    <td class="text-left">Product Code</td>
                    <td class="text-left">Quantity</td>
                    <td class="text-right">Unit Price</td>
                    <td class="text-right">Total</td>
					<td class="text-center">Action</td>

                  </tr>
                </thead>
                <tbody>
				{cartItems.map((item) => (
					<>
                  <tr>
                    <td class="text-center">
						<a><img width="70px" src={item.image} alt={item.product_name} title="Aspire Ultrabook Laptop" class="img-thumbnail" /></a></td>
                    <td class="text-left"><a >{item.product_name}</a><br />
                     </td>
                    <td class="text-left">{item.product_code}</td>
					{console.log(item.code)}
                    <td class="text-left" width="200px">
					<div className="productdetail-qty1">
							{/* {item.stock > 0 ? ( */}
								<>
								<Button
									variant="secondary"
									className="in-dec-button"
									onClick={() => handleDecrease(item.product_id, item.image, item.qty)}
								>
									-
								</Button>
								&nbsp;
								<span className="mx-2">{item.qty}</span>
								&nbsp;
								<Button
									variant="secondary"
									className="in-dec-button"
									onClick={() => handleIncrease(item.product_id, item.image, item.qty)}
								>
									+
								</Button>
								</>
							{/* ) : (
								<span className="out-of-stock-message"><strong>Out of Stock</strong></span>
							)} */}
							</div>

						</td>
                    <td class="text-right">
					{/* {item.stock > 0 ? ( */}
                     <>
					<i class="bi bi-currency-rupee"></i>{item.onlinerate}
					</>
					{/* ):(
						<span className="out-of-stock-message" >0</span>
					)} */}
					</td>
                    <td class="text-right">
						{/* {item.stock > 0 ? ( */}
							<>
						<i class="bi bi-currency-rupee"></i>{(item.onlinerate * item.qty).toFixed(2)}
						</>
						{/* ):(
							<span className="out-of-stock-message" >0</span>
						)} */}
						</td>

					
					<td className="text-center"  
							style={{ cursor: 'pointer', color: 'red', fontSize: "14px" }}
							onClick={() => {
								if (window.confirm("Do you want to delete the product from cart?")) {
									removeItemFromCart(item.product_id, item.image);
								}
							}}
						>
							<i className="bi bi-trash"></i>
					</td>


                  </tr>
                  
				  </>
				  ))}
                </tbody>
              </table>
            </div>
    	{/* <div class="col-sm-12">
					  <div class="panel panel-default">
						<div class="panel-heading">
						  <h4 class="panel-title"><i class="fa fa-ticket"></i> Do you Have a Coupon</h4>
						</div>
						  <div class="panel-body row">
							<div class="col-sm-6 ">
							<div class="input-group">
							  <input type="text" class="form-control" id="input-coupon" placeholder="Enter your coupon here" value="" name="coupon" />
							  <span class="input-group-btn">
							  <input type="button" class="btn btn-primary" data-loading-text="Loading..." id="button-coupon" value="Apply Coupon"/>
							  </span></div>
							</div>
							
							
						  </div>
					  </div>
					</div> */}
		<div class="row">
			<div class="col-sm-4 col-sm-offset-8">
			<table class="table table-bordered">
				<tbody>
					<tr>
					<td class="text-right">
						<strong>Sub-Total:</strong>
					</td>
					<td class="text-right"><i class="bi bi-currency-rupee"></i>{subtotal.toFixed(2)}</td>
					</tr>
			
					{/* <tr>
					<td class="text-right">
						<strong>CGST:</strong>
					</td>
					<td class="text-right"><i class="bi bi-currency-rupee"></i>{cgst.toFixed(2)}</td>
					</tr>
					<tr>
					<td class="text-right">
						<strong>SGST:</strong>
					</td>
					<td class="text-right"><i class="bi bi-currency-rupee"></i>{sgst.toFixed(2)}</td>
					</tr>
					<tr>
					<td class="text-right">
						<strong>Total:</strong>
					</td>
					<td class="text-right"><i class="bi bi-currency-rupee"></i>{total.toFixed(2)}</td>
					</tr> */}
				</tbody>
				</table>
			</div>
		</div>

		 <div class="buttons">

            <div class="pull-left"><Link to="/" class="btn btn-primary">Continue Shopping</Link></div>
           
            <div class="pull-right"><p onClick={handleCheckout}  class="btn btn-primary">Checkout</p></div>
          </div>
        </div>
       {/*  <!--Middle Part End -->
			 */}
		</div>
	</div>
    </div>
    </div>
    <Footer />
    {/* </>
	  )} */}
	  </>
  )
}

export default Cart