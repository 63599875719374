import React,{useState,useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Loader from '../Components/Loader';


const Contact = () => {
const [adminData, setAdminData] = useState({});
const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		  // Fetch API data
		  const fetchFooterData = async () => {
			try {
			  const response = await fetch('https://unitedmart.in/customerapp/footer.php', {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json',
				},
			  });
	  
			  const data = await response.json();
			  if (data.head.code === 200) {
				setAdminData(data.body.admin_data || {});
			  }
			} catch (error) {
			  console.error('Error fetching footer data:', error);
			}
		  };
	  
		  fetchFooterData();
		}, []);
		useEffect(() => {
		// Simulate a network request or loading process
		const timer = setTimeout(() => setIsLoading(false), 3000);
		return () => clearTimeout(timer);
		}, []);


  return (
    <>
	{/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
    <div className="common-home res layout-home1">
      <div id="wrapper" className="wrapper-full banners-effect-7">
        <Header />
        <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="index.html"><i class="fa fa-home"></i></a></li>
			<li>Contact us</li>			
		</ul>
		
		<div class="row">
			<div id="content" class="col-sm-12">
				{/* <div class="page-title">
					<h2>Contact Us</h2>
				</div> */}
				
				
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7871.195592654986!2d77.806466!3d9.456621!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06cef1a1bc0875%3A0x2fa529708d2148c2!2sUnited%20Papers%20Sivakasi!5e0!3m2!1sen!2sus!4v1737009433566!5m2!1sen!2sus"
					width="100%"
					height="450"
					style={{ border: "0" }}
					allowFullScreen
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					/>			
					
	<div class="info-contact clearfix">
					<div class="col-lg-4 col-sm-4 col-xs-12 info-store">
						<div class="row">
							<div class="name-store">
							<br />
								<h3>Contact Address</h3>
							</div>
							<address>
								<div class="address clearfix form-group">
									<div class="icon">
										<i class="fa fa-home"></i>
									</div>
									<div class="text"><strong>UnitedMart</strong> <br />{adminData.address}, <br />
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{adminData.city}, <br />
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{adminData.state}-{adminData.pincode}
									</div>
								</div>
								<div class="phone form-group">
									<div class="icon">
										<i class="fa fa-phone"></i>
									</div>
									<div class="text"><strong>Phone :</strong>{adminData.phonenumber} </div><br />
									<div class="icon">
										<i class="fa fa-envelope"></i>
									</div>
									<div class="text"><strong>Email Id: </strong>{adminData.email}</div>
									
								</div>
								
							</address>
						</div>
					</div>
					<br />
					<div class="col-lg-8 col-sm-8 col-xs-12 contact-form">
						<form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
							<fieldset>
								<legend>Contact Form</legend>
								<div class="form-group required">
							<label class="col-sm-2 control-label" for="input-name">Your Name</label>
							<div class="col-sm-10">
								<input type="text" name="name" value="" id="input-name" class="form-control" />
								</div>
							</div>
							<div class="form-group required">
								<label class="col-sm-2 control-label" for="input-email">E-Mail Address</label>
								<div class="col-sm-10">
									<input type="text" name="email" value="" id="input-email" class="form-control" />
									</div>
								</div>
								<div class="form-group required">
									<label class="col-sm-2 control-label" for="input-enquiry">Enquiry</label>
									<div class="col-sm-10">
										<textarea name="enquiry" rows="10" id="input-enquiry" class="form-control"></textarea>
									</div>
								</div>
							</fieldset>
							<div class="buttons">
								<div class="pull-right">
									<button class="btn btn-default buttonGray" type="submit">
										<span>Submit</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
    <Footer />
      </div>
    </div>
	{/* </>
	  )} */}
</>
  )
}

export default Contact