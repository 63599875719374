import React,{useState, useEffect, useContext } from "react";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css"; // Ensure correct CSS path
import "owl.carousel/dist/assets/owl.theme.default.css"; // Ensure correct CSS path
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import {  Button } from 'react-bootstrap';
import axios from "axios";
import { useParams ,Link } from 'react-router-dom';
import { CartContext } from "../Context/CartContext"; // Adjust the import path based on your project structure
import { FaShoppingCart } from 'react-icons/fa';
import { FaHeart } from "react-icons/fa";
import Loader from '../Components/Loader';

const ProductDetail = () => {
	const { product_id } = useParams();
	const [product, setProduct] = useState(null);

	const [quantity, setQuantity] = useState(product?.qty ?? 1);
	const [fullDescription, setFullDescription] = useState("");
	const [wishlist, setWishlist] = useState([]);
	const [wishlistmessage, setWishListMessage] = useState('');
	const [message, setMessage] = useState('');
    const { addItemToCart, updateItemQuantity, cartItems, setCartItems } = useContext(CartContext);
    // console.log(cartItems);
	const [title, setTitle] = useState([]);
	const [subtitle, setSubTitle] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [relatedproduct, setRelatedProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
	const minQuantity = product?.qty ?? 1; // Minimum allowed quantity

	useEffect(() => {
		if (product?.qty) {
		  setQuantity(Number(product.qty) || 1);
		}
	  }, [product]);


  useEffect(() => {
    // Ensure the initial state is set to the first image when the product changes
    if (product?.image?.length > 0) {
      setSelectedImage(product.image[0]);
    }
  }, [product]);

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };
	useEffect(() => {
    // Fetch product details from the API
    axios
      .post("https://unitedmart.in/customerapp/product_details.php", {
        product_id: product_id,
      })
      .then((response) => {
        if (response.data.head.code === 200) {
          const fetchedProduct = response.data.body.product_detail[0];
          setProduct(fetchedProduct);
		  console.log("productdetail",fetchedProduct )
          // Set full description directly from the response
          setFullDescription(fetchedProduct.full_description || 'No description available');
		//   setRelatedProduct(response.data.body.related_product)
		  setTitle(response.data.body.top_title[0].main_category_name);
          setSubTitle(response.data.body.top_title[0].sub_category_name);
          console.log("Full Description", fetchedProduct.full_description);
        } else {
          console.error("Failed to fetch product details");
        }
      })
      .catch((error) => console.error("Error fetching product details:", error));
  }, [product_id]);

  


	  useEffect(() => {
		if (product) {
		  const cartItem = cartItems.find(
			(item) => item.product_id === product.product_id && item.image === product.image[0]
		  );
		  if (cartItem) {
			setQuantity(cartItem.qty);
		  }
		}
	  }, [cartItems, product]);
	
	  const handleIncrease = () => {
		if (product) {
		  if (quantity + 1 > product.stock) {
			alert('Stock limit reached!');
			return;
		  }
		  const newQty = quantity + 1;
		  setQuantity(newQty);
		  updateItemQuantity(product.product_id, product.image[0], newQty);
		}
	  };
	  
	
	  const handleDecrease = () => {
		setQuantity((prevQty) => {
		  if (prevQty > minQuantity) {
			const newQty = prevQty - 1;
			updateItemQuantity(product.product_id, product.image[0], newQty);
			return newQty;
		  }
		  return prevQty; // Prevent decreasing below minQuantity
		});
	  };

	  const handleAddToCart = (product, resetQuantity = false) => {
		if (product) {
		  setCartItems((prevCartItems) => {
			const existingProduct = prevCartItems.find(
			  (item) => item.product_id === product.product_id
			);
	  
			if (existingProduct) {
			  console.log("Product is already in the cart.");
			  return prevCartItems; // Return the unchanged cart
			}
	  
			const finalQuantity = resetQuantity ? 1 : quantity; // Reset quantity to 1 for related products
	  
			return [
			  ...prevCartItems,
			  {
				product_id: product.product_id,
				image:selectedImage,
				product_name: product.product_name,
				onlinerate: product.online_rate,
				tax_amt: product.tax_amt,
				qty: finalQuantity,
				product_code: product.product_code,
				tot: product.online_rate * finalQuantity,
				maincategory_id: product.maincategory_id,
				subcategory_id: product.subcategory_id,
				stock: product.stock,
				unit: product.unit,
				minimumquantity:product.qty,
			  },
			];
		  });
		  setMessage('Item Added To Cart!');
    
		  setTimeout(() => {
			setMessage('');
		  }, 2500);
		  console.log("Product added to cart:", product);
		}
	  };
	  
	  
	  
	  
	  useEffect(() => {
		  const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
		  setWishlist(storedWishlist);
		}, []);
	  
		// Add product to wishlist
		const handleAddToWishlist = (product) => {
			const userId = localStorage.getItem("user_id");
			console.log("USERID",localStorage.getItem("user_id"));
			if (!userId) {
			  alert("Please log in to add items to your wishlist.");
			  return;
			}
		
			const storedWishlist = JSON.parse(localStorage.getItem(`wishlist_${userId}`)) || [];
			const isAlreadyInWishlist = storedWishlist.some(
			  (item) => item.product_id === product.product_id
			);
		
			if (!isAlreadyInWishlist) {
				const updatedProduct = {
					...product,
					image:selectedImage,
				  };
			  const updatedWishlist = [...storedWishlist, updatedProduct];
			  localStorage.setItem(`wishlist_${userId}`, JSON.stringify(updatedWishlist)); // Save to localStorage
			  setWishlist(updatedWishlist); // Update state
			  alert("Product added to wishlist!");
			  setWishListMessage('Item Added To Wishlist');
			  setTimeout(() => {
				setWishListMessage('');
			  }, 2500);
			} else {
			  alert("Product is already in your wishlist.");
			}
		  };


		  useEffect(() => {
			// Simulate a network request or loading process
			const timer = setTimeout(() => setIsLoading(false), 3000);
			return () => clearTimeout(timer);
		  }, []);

	
	  if (!product) {
		return <div> </div>;
	  }
  

	const options = {
		margin: 25, // Space between items
		responsiveClass: true,
		autoplay: true,
        nav: true, // Enable navigation
		dots: true, // Enable dots for pagination
		responsive: {
		  0: {
			items: 1,
		  },
		  600: {
			items: 2,
		  },
		  1000: {
			items: 4,
		  },
		},
	  };
	
	//   const products = [
	// 	{ id: 1, imgSrc: "assets/images/products/2.jpg", title: "Product 1", priceNew: "74.00", priceOld: "122.00", discount: "-40%" },
	// 	{ id: 2, imgSrc: "assets/images/products/3.jpg", title: "Product 2", priceNew: "74.00", priceOld: "122.00", discount: "-40%" },
	// 	{ id: 3, imgSrc: "assets/images/products/4.jpg", title: "Product 3", priceNew: "74.00", priceOld: "122.00", discount: "-40%" },
	// 	{ id: 4, imgSrc: "assets/images/products/5.jpg", title: "Product 4", priceNew: "74.00", priceOld: "122.00", discount: "-40%" },
	//   ];
	  function capitalizeFirstLetter(str) {
		if (!str) return ''; // Handle empty or undefined strings
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
		}
	

  return (
    <>
	{/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
    <div class="common-home res layout-home1">
        <div id="wrapper" class="wrapper-full banners-effect-7">
            <Header />
			 {message && (
				<div className="message-popup">
				    <FaShoppingCart className="cart-icon-addtocart" />
						<div className="message">{message}</div>
						</div>
					    )}
			 {wishlistmessage && (
				<div className="wishlist-message-popup">
				    <FaHeart className="heart-icon-addtowishlist" />
					    <div className="wishlist-message">{wishlistmessage}</div>
						</div>
					)}
            <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="#"><i class="fa fa-home"></i></a></li>
			<li>{title}</li>
              {/* <li>{capitalizeFirstLetter(subtitle)}</li> */}
		</ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
			<div id="content" class="col-md-12 col-sm-12">
				
				<div class="product-view row">
					<div class="left-content-product col-lg-10 col-xs-12">
						<div class="row">
						<div className="content-product-left col-sm-7 col-xs-12">
								<div id="thumb-slider-vertical" className="thumb-vertical-outer">
									<span className="btn-more prev-thumb nt">
									<i className="fa fa-angle-up"></i>
									</span>
									<span className="btn-more next-thumb nt">
									<i className="fa fa-angle-down"></i>
									</span>
									<ul className="thumb-vertical">
									{product?.image?.length > 0 ? (
										product.image.map((img, index) => (
										img ? (
											<li className="owl2-item" key={index}>
											<a
												data-index={index}
												className="img thumbnail"
												data-image={img}
												title={product.product_name}
												onClick={() => handleImageClick(img)}
											>
												<img src={img} alt={product.product_name} />
											</a>
											</li>
										) : null
										))
									) : (
										<p>No images available</p>
									)}
									</ul>
								</div>
								<div className="large-image vertical">
									{selectedImage ? (
									<InnerImageZoom
										src={selectedImage}
										zoomSrc={selectedImage}
										zoomType="hover"
									/>
									) : (
									<p>No image available</p>
									)}
								</div>
								</div>

							<div className="content-product-right col-sm-5 col-xs-12">
									<div className="title-product">
										<h1>{product?.product_name || "Loading product name..."}</h1> {/* Display a fallback message if the product name is not available */}
										<div className="product-box-desc">
										<div className="inner-box-desc">
											<div className="model"><span>Product Code:</span> {product?.product_code || "N/A"}</div> {/* Fallback for product code */}
											<div className="model"><span>Minimum Order Quantity : </span> {product?.minimum_qty || "N/A"} </div>
										</div>
										</div>
										{/* <p>{product?.short_description || "No description available"}</p>  */}
									</div>

                                     {/* Stock  */}
									{/* <nobr>
										<div className="stock" style={{display:"flex"}}>
										<span>Availability:</span> 
										<span className="status-stock">
											{ product?.stock > 0 ? (

												<>
												<span style={{color:"green"}}>
												&nbsp; In Stock
												</span>
												
												</>
											):(
												<>
												<span style={{color:"red"}}>
												&nbsp; Out of Stock
												</span>
												</>
											)}
											</span>
											&nbsp;&nbsp; 
											<div style={{
										color: parseInt(product?.stock, 10) === 0 ? 'red' : parseInt(product?.stock, 10) < 10 ? 'red' : 'green',
									}}>
										{product.stock > 0 ? (
											<>
											|&nbsp;&nbsp;
											{product?.stock}
											</>
										):(
											<>
											
											</>
										)}
											</div>
											
										<div>
										<div className="stock"  style={{
										color: parseInt(product?.stock, 10) === 0 ? 'red' : parseInt(product?.stock, 10) < 10 ? 'red' : 'green',
									}}>
										<span>In stock :</span> <span className="">{product?.stock || "Out of stock"}</span> &nbsp;&nbsp;
										</div>
										</div>
										</div>
										</nobr> */}

									{/* Review */}
									<div className="product-label form-group">
										<div className="product_page_price price"><br />
										<span className="price-new" itemprop="price">
											<i className="bi bi-currency-rupee"></i>{product?.online_rate || "Price not available"}
										</span>
									{/* 	{product?.mrp_rate !== "0.00" && (
											<span className="price-old">
											<i className="bi bi-currency-rupee"></i>
											{product?.mrp_rate || "N/A"}
											</span>
										)} */}
										</div>
										
										
									</div>
									

									{/* <div className="product-box-desc"><br />
										<div className="inner-box-desc">
										<div className="model">
											<span>HSN Code:</span>{product?.hsn_code || "Not available"}
										</div>
										</div>
									</div> */}
								
									<div id="product">
										<div className="form-group box-info-product">
										<div className="option quantity">
											<div className="" unselectable="on" style={{ WebkitUserSelect: "none" }}>
											<nobr>
											<div className="productdetail-qty1">
												<span className="productdetail-qty">Qty: </span> &nbsp;
												<Button variant="secondary" className="in-dec-button" onClick={handleDecrease}>
												-
												</Button> &nbsp; 
												<span className="mx-2">{quantity}</span> &nbsp; 
												<Button variant="secondary" className="in-dec-button" onClick={handleIncrease}>
												+
												</Button>
											</div>&nbsp;&nbsp;&nbsp;&nbsp;
											</nobr>
											</div>
											
										</div> &nbsp;&nbsp;&nbsp;&nbsp;
										<div className="cart">
											<input type="button" data-toggle="tooltip" title="" value="Add to Cart" 
											data-loading-text="Loading..." id="button-cart" className="btn btn-mega btn-lg" 
											onClick={() => handleAddToCart(product)} data-original-title="Add to Cart" />
										</div>
										<div className="add-to-links wish_comp">
											<ul className="blank list-inline">
											<li className="wishlist">
												<a
													className="icon"
													data-toggle="tooltip"
													title="Add to Wish List"
													onClick={() => handleAddToWishlist(product)} // Pass product data explicitly
												>
													<i className="fa fa-heart"></i>
												</a>
											</li>

											</ul>
										</div>
                                        

										</div>
										
									</div>
									<div>
									<table>
									   <tbody>
										   <tr>
											  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight:"bold" }}>Bursting factor</td>
											  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product?.burstingfactor }</td>
										   </tr>
										   <tr>
											  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight:"bold"  }}> Size</td>
											  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product?.size }</td>
										   </tr>
										   {/* <tr>
											  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight:"bold"  }}>Capacity</td>
											  <td style={{ border: "1px solid #ddd", padding: "8px" }}>0.5kg</td>
										   </tr> */}
										   <tr>
											  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight:"bold"  }}>Gsm</td>  
											  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product?.gsm }</td>
										   </tr>
										   <tr>
											  <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight:"bold"  }}>Unit</td>
											  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{product?.unit }</td>
										   </tr>
										  
									    </tbody>
									</table>
										</div>

								{/* <!-- end box info product --> */}

							</div>
						</div>
					</div>
				</div>
				<br />
				{/* <!-- Product Tabs --> */}
				<div class="producttab ">
	                     <div class="tabsslider  col-xs-12">
		                    <ul class="nav nav-tabs">
			                    <li class="active"><a data-toggle="tab" href="#tab-1">Description</a></li>
			                        {/* <li class="item_nonactive"><a data-toggle="tab" href="#tab-review">Reviews (1)</a></li> */}
		                    </ul>
					<div class="tab-content col-xs-12">
						<div id="tab-1" class="tab-pane fade active in">
				           <p>
 								{fullDescription}
				
							</p>  
						</div>
			<div id="tab-review" class="tab-pane fade">
				<form>
					<div id="review">
						<table class="table table-striped table-bordered">
							<tbody>
								<tr>
									<td style={{width: "50%"}}><strong>Super Administrator</strong></td>
									<td class="text-right">29/07/2015</td>
								</tr>
								<tr>
									<td colspan="2">
										<p>Best this product opencart</p>
										<div class="ratings">
											<div class="rating-box">
												<span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>
												<span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>
												<span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>
												<span class="fa fa-stack"><i class="fa fa-star fa-stack-1x"></i><i class="fa fa-star-o fa-stack-1x"></i></span>
												<span class="fa fa-stack"><i class="fa fa-star-o fa-stack-1x"></i></span>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="text-right"></div>
					</div>
					{/* <h2 id="review-title">Write a review</h2>
					<div class="contacts-form">
						<div class="form-group"> <span class="icon icon-user"></span>
							<input type="text" name="name" class="form-control" value="Your Name" onblur="if (this.value == '') {this.value = 'Your Name';}" onfocus="if(this.value == 'Your Name') {this.value = '';}"/> 
						</div>
						<div class="form-group"> <span class="icon icon-bubbles-2"></span>
							<textarea class="form-control" name="text" onblur="if (this.value == '') {this.value = 'Your Review';}" onfocus="if(this.value == 'Your Review') {this.value = '';}">Your Review</textarea>
						</div> 
						<span style={{fontSize: "11px"}}><span class="text-danger">Note:</span>	HTML is not translated!</span>
						
						<div class="form-group">
						 <b>Rating</b> <span>Bad</span>&nbsp;
						<input type="radio" name="rating" value="1" /> &nbsp;
						<input type="radio" name="rating"
						value="2" /> &nbsp;
						<input type="radio" name="rating"
						value="3" /> &nbsp;
						<input type="radio" name="rating"
						value="4" /> &nbsp;
						<input type="radio" name="rating"
						value="5" /> &nbsp;<span>Good</span>
						
						</div>
						<div class="buttons clearfix"><a id="button-review" class="btn buttonGray">Continue</a></div>
					</div> */}
				</form>
			</div>
			{/* <div id="tab-4" class="tab-pane fade">
				<a href="#">Monitor</a>,
				<a href="#">Apple</a>				
			</div>
			<div id="tab-5" class="tab-pane fade">
				<p>Lorem ipsum dolor sit amet, consetetur
					sadipscing elitr, sed diam nonumy eirmod
					tempor invidunt ut labore et dolore
					magna aliquyam erat, sed diam voluptua.
					At vero eos et accusam et justo duo
					dolores et ea rebum. Stet clita kasd
					gubergren, no sea takimata sanctus est
					Lorem ipsum dolor sit amet. Lorem ipsum
					dolor sit amet, consetetur sadipscing
					elitr, sed diam nonumy eirmod tempor
					invidunt ut labore et dolore magna aliquyam
					erat, sed diam voluptua. </p>
				<p>At vero eos et accusam et justo duo dolores
					et ea rebum. Stet clita kasd gubergren,
					no sea takimata sanctus est Lorem ipsum
					dolor sit amet. Lorem ipsum dolor sit
					amet, consetetur sadipscing elitr.</p>
				<p>Sed diam nonumy eirmod tempor invidunt
					ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At vero eos et accusam
					et justo duo dolores et ea rebum. Stet
					clita kasd gubergren, no sea takimata
					sanctus est Lorem ipsum dolor sit amet.</p>
			</div> */}
		</div>
	</div>
</div>

			{/* 	<!-- //Product Tabs -->
				
				<!-- Related Products --> */}
				{/* <div class="related titleLine products-list grid module ">
	<h3 class="modtitle">Related Products  </h3>
	<div class="releate-products ">
	<OwlCarousel className="owl-theme" {...options}>
      {relatedproduct.map((product) => (
        <div className="product-layout" key={product.id} style={{ width: "273.35px", marginRight: "25px" }}>
          <div className="product-item-container">
		  <Link to={`/productdetail/${product.product_id}`}>
            <div className="left-block">
				
              <div className="product-image-container second_img">
                <img
                  src={product.image}
                  title={product.product_name}
                  className="img-responsive"
                  alt={product.product_name}
                />
                <img
                  src={product.image}
                  alt={product.product_name}
                  className="img_0 img-responsive"
                />
              </div>
              <span className="label label-sale">Sale</span>
            </div>
			</Link>
            <div className="right-block">
              <div className="caption">
                <h4><a href="product.html">{product.product_name}</a></h4>
                <div className="price">
                  <span className="price-new"><i class="bi bi-currency-rupee"></i>{product.online_rate}</span>
                  <span className="price-old"><i class="bi bi-currency-rupee"></i>{product.mrp_rate}</span>
                  <span className="label label-percent">{product.discount}</span>
                </div>
                <div className="description item-desc hidden">
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut...</p>
                </div>
              </div>
			
              <div className="button-group">
                <button className="addToCart" type="button" >
                  <i className="fa fa-shopping-cart"></i> <span className="hidden-xs"onClick={() => handleAddToCart(product, true)} >Add to Cart</span>
                </button>
                <button className="wishlist" type="button" onClick={() => handleAddToWishlist(product)}>
                  <i className="fa fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
	  <div className="owl2-controls">
	  <div className="owl2-nav">
	  <div className="owl2-prev">
	  
</div>
<div className="owl2-next">

</div>
	  </div>
	  </div>
    </OwlCarousel>
	</div>
</div> */}
</div>
			
			
		</div>
		{/* <!--Middle Part End--> */}
	</div>
  </div>
</div>
<Footer />
    {/* </>
	  )} */}
	  </>
    
  );
};

export default ProductDetail;
