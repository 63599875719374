import React, { useEffect, useState,useContext } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import axios from "axios";
import { CartContext } from "../Context/CartContext"; // Import the CartContext
import { ClipLoader } from "react-spinners";
import Loader from '../Components/Loader';



const Checkout = () => {
  const [addresses, setAddresses] = useState([]);
  const userId = localStorage.getItem('user_id');
  const Email = localStorage.getItem('email');
  const [isLoading, setIsLoading] = useState(true);
  const [travelsName, setTravelsName] = useState(""); // State for Travels Name
  const [paymentType, setPaymentType] = useState("");
  console.log('Retrieved User ID:', userId);
  const [loading, setLoading] = useState(false);

  const { cartItems, updateItemQuantity, removeItemFromCart,setCartItems } = useContext(CartContext);
  const [billingDetails, setBillingDetails] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [comments, setComments] = useState("");
  const [agree, setAgree] = useState(false);
  const navigate=useNavigate();
console.log("cartItems", cartItems);
  const handleCheckboxChange = (e) => {
    setAgree(e.target.checked);
  };

//   const subtotal = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + item.onlinerate * item.qty : acc),
// 	0
//   );
  
//   const cgst = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + (item.tax_amt / 2) * item.qty : acc),
// 	0
//   );
  
//   const sgst = cartItems.reduce(
// 	(acc, item) => (item.stock > 0 ? acc + (item.tax_amt / 2) * item.qty : acc),
// 	0
//   );
  
//   const total = subtotal + cgst + sgst;
  
const subtotal = cartItems.reduce(
	(acc, item) => acc + item.onlinerate * item.qty,
	0
  );
  
  const cgst = cartItems.reduce(
	(acc, item) => acc + (item.tax_amt / 2) * item.qty,
	0
  );
  
  const sgst = cartItems.reduce(
	(acc, item) => acc + (item.tax_amt / 2) * item.qty,
	0
  );

  const igst = cgst + sgst
  
  const total = subtotal + cgst + sgst;

useEffect(() => {
	  if (!userId) {
		navigate('/login'); // Redirect to login if user is not logged in
	  }
	}, [userId, navigate]);

  
  useEffect(() => {
    // Fetch address details
    const fetchAddresses = async () => {
      try {
        const response = await axios.post(
          "https://unitedmart.in/customerapp/addresslist.php",
          { user_id: userId }
        );
        if (response.data.head.code === 200) {
          setAddresses(response.data.body);
        } else {
          console.error("Failed to fetch addresses:", response.data.head.msg);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [userId]);



  
  const handleConfirmOrder = async () => {
	if (!agree) {
	  alert("Please agree to the terms and conditions.");
	  return;
	}
	setLoading(true); // Show loading spinner
  
	// Filter out items with stock 0
	// const availableItems = cartItems.filter((item) => item.stock > 0);
	const availableItems = cartItems;

	
  
	// if (availableItems.length === 0) {
	//   alert("No products available in stock to place the order.");
	//   setLoading(false);
	//   return;
	// }
  
	const orderPayload = {
	  user_id: userId, // Replace with the logged-in user ID
	  payment_type: paymentType,
	  travels: travelsName,
	  billing_details: {
		billing_name: billingDetails.name || addresses[0]?.name,
		billing_address: billingDetails.address || addresses[0]?.address,
		billing_city: billingDetails.city || addresses[0]?.city,
		billing_state: billingDetails.state || addresses[0]?.state,
		billing_email: Email, 
		billing_phonenumber: billingDetails.phonenumber || addresses[0]?.phonenumber,
		billing_postcode: billingDetails.pincode || addresses[0]?.pincode,
	  },
	  delivery_details: {
		delivery_name: deliveryDetails.shipping_name || addresses[0]?.shipping_name,
		delivery_address: deliveryDetails.shipping_address || addresses[0]?.shipping_address,
		delivery_city: deliveryDetails.shipping_city || addresses[0]?.shipping_city,
		delivery_state: deliveryDetails.shipping_state || addresses[0]?.shipping_state,
		delivery_email: Email, 
		delivery_phonenumber: deliveryDetails.shipping_phonenumber || addresses[0]?.shipping_phonenumber,
		delivery_postcode: deliveryDetails.shipping_pincode || addresses[0]?.shipping_pincode,
	  },
	  subtotal: availableItems.reduce((sum, item) => sum + item.onlinerate * item.qty, 0),
	  sgst: sgst,
	  cgst: cgst,
	  total: total,
	  note: comments,
	  productdetails: availableItems.map((item) => ({
		product_id: item.product_id,
		product_name: item.product_name,
		qty: item.qty,
		amount: item.onlinerate,
		totalamount: item.onlinerate * item.qty,
		unit: item.unit,
	  })),
	};
  
	console.log(orderPayload);
  
	try {
	  const response = await axios.post("https://unitedmart.in/customerapp/order.php", orderPayload);
	  if (response.data?.head?.code === 200) {
		await new Promise((resolve) => setTimeout(resolve, 2000));
		alert("Order placed successfully!");
		// Navigate to the order confirmation page
		setCartItems([]); // If cartItems is managed via state
		localStorage.removeItem("cartItems"); //
		navigate('/orderhistory');
	  } else {
		alert("Failed to place the order. Please try again.");
	  }
	} catch (error) {
	  console.error("Error placing order:", error);
	  alert("An error occurred. Please try again.");
	} finally {
	  setLoading(false); // Stop the loading spinner
	}
  };
    useEffect(() => {
	// Simulate a network request or loading process
	const timer = setTimeout(() => setIsLoading(false), 3000);
	return () => clearTimeout(timer);
	}, []);

  return (
    <>
	{/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
<div class="common-home res layout-home1">
  <div id="wrapper" class="wrapper-full banners-effect-7">
    <Header />
    <div class="main-container container">
		<ul class="breadcrumb">
			<li><Link to= '/' ><i class="fa fa-home"></i></Link></li>
			<li><a >Checkout</a></li>
			
		</ul>
		
		<div class="row">
			{/* <!--Middle Part Start--> */}
			<div id="content" class="col-sm-12">
			  <h2 class="title">Checkout</h2>
			  <div class="so-onepagecheckout ">
				  <div class="col-right col-sm-12">
				  <div class="row">
					<div class="col-sm-12">
						<div class="panel panel-default no-padding">
							
							<div className="checkout-addresses">
							{addresses.length > 0 ? (
									addresses.map((address, index) => (
										<div className="row" key={address.address_id}>
										{/* Billing Address */}
										{index === 0 && (
											<div className="col-sm-6 checkout-shipping-methods">
											<div className="panel-heading">
												<h4 className="panel-title">
												<i className="fa fa-book"></i> Billing Address
												</h4>
											</div>
											<div className="panel-body">
												<Link to="/myaccount">
												<div className="buttons">
													<div className="pull-right">
													<button
														type="button"
														className="btn btn-primary"
														id="button-confirm"
													>
														Edit Address
													</button>
													</div>
												</div>
												</Link>
												<p>{address.name}</p>
												<p>{address.address}</p>
												<p>{address.city}</p>
												<p>{`${address.state} - ${address.pincode}`}</p>
												<p>{address.phonenumber}</p>
											</div>
											</div>
										)}

										{/* Shipping Address */}
										{index === 0 && (
											<div className="col-sm-6 checkout-payment-methods">
											<div className="panel-heading">
												<h4 className="panel-title">
												<i className="fa fa-building"></i> Shipping Address
												</h4>
											</div>
											<div className="panel-body">
												<Link to="/myaccount">
												<div className="buttons">
													<div className="pull-right">
													<button
														type="button"
														className="btn btn-primary"
														id="button-confirm"
													>
														Edit Address
													</button>
													</div>
												</div>
												</Link>
												<p>{address.shipping_name}</p>
												<p>{address.shipping_address}</p>
												<p>{address.shipping_city}</p>
												<p>{`${address.shipping_state} - ${address.shipping_pincode}`}</p>
												<p>{address.shipping_phonenumber}</p>
											</div>
											</div>
										)}
										</div>
									))
									) : (
									<div className="row">
										<div className="col-sm-6 checkout-shipping-methods">
										<div className="panel-heading">
											<h4 className="panel-title">
											<i className="fa fa-book"></i> Billing Address
											</h4>
										</div>
										<div className="panel-body">
											<Link to="/myaccount">
											<div className="buttons">
												<div className="pull-right">
												<button
													type="button"
													className="btn btn-primary"
													id="button-confirm"
												>
													Add Address
												</button>
												</div>
											</div>
											</Link>
										</div>
										</div>
										<div className="col-sm-6 checkout-payment-methods">
										<div className="panel-heading">
											<h4 className="panel-title">
											<i className="fa fa-building"></i> Shipping Address
											</h4>
										</div>
										<div className="panel-body">
											<Link to="/myaccount">
											<div className="buttons">
												<div className="pull-right">
												<button
													type="button"
													className="btn btn-primary"
													id="button-confirm"
												>
													Add Address
												</button>
												</div>
											</div>
											</Link>
										</div>
										</div>
									</div>
									)}

							</div>
						</div>
						
						</div>
					 
												
						<div className="col-md-12">
							<div className="panel panel-default">
								<div className="panel-heading">
								<h4 className="panel-title">
									<i className="fa fa-shopping-cart"></i> Shopping Cart
								</h4>
								</div>
								<div className="panel-body">
								<div className="table-responsive">
									<table className="table table-bordered">
									<thead>
										<tr>
										<td className="text-center">Image</td>
										<td className="text-left">Product Name</td>
										<td className="text-left">Product Code</td>
										<td className="text-left">Quantity</td>
										<td className="text-right">Unit Price</td>
										<td className="text-right">Total</td>
										</tr>
									</thead>
									<tbody>
											{cartItems.length > 0 ? (
												cartItems
												.filter((item) => cartItems) // Filter out items with stock 0
												.map((item) => (
													<tr key={`${item.product_id}-${item.image}`}>
													<td className="text-center">
														<img
														width="60px"
														src={item.image}
														alt={item.product_name}
														className="img-thumbnail"
														/>
													</td>
													<td className="text-left">{item.product_name}</td>
													<td className="text-left">{item.product_code}</td>
													<td className="text-left">
														<div className="input-group btn-block" style={{ minWidth: "100px" }}>
														{item.qty}
														</div>
													</td>
													<td className="text-right">
														<>
														<i className="bi bi-currency-rupee"></i>
														{Number(item.onlinerate || 0).toFixed(2)}
														</>
													</td>
													<td className="text-right">
														<>
														<i className="bi bi-currency-rupee"></i>
														{Number(item.onlinerate * item.qty || 0).toFixed(2)}
														</>
													</td>
													</tr>
												))
											) : (
												<tr>
												<td colSpan="5" className="text-center">
													No items in the cart.
												</td>
												</tr>
											)}
											</tbody>

									<tfoot>
										<tr>
										<td className="text-right" colSpan="5">
											<strong>Sub-Total:</strong>
										</td>
										<td className="text-right">
											<i className="bi bi-currency-rupee"></i>
											{subtotal.toFixed(2)}   
											
										</td>
										</tr>
										{/* {console.log(" State" , addresses[0].shipping_state)} */}
										{Array.isArray(addresses) && addresses.length > 0 && addresses[0]?.shipping_state?.trim().toLowerCase() === "tamilnadu" ? (
											
  										<>
										<tr>

										<td className="text-right" colSpan="5">
											<strong>CGST :</strong>
										</td>
										<td className="text-right">
											<i className="bi bi-currency-rupee"></i>{cgst.toFixed(2)}
										</td>
										</tr>
										<tr>
										<td className="text-right" colSpan="5">
											<strong>SGST :</strong>
										</td>
										<td className="text-right">
											<i className="bi bi-currency-rupee"></i>{sgst.toFixed(2)}
										</td>
										</tr>
                                        </>
										):(
											<tr>
											<td className="text-right" colSpan="5">
												<strong>IGST :</strong>
											</td>
											<td className="text-right">
												<i className="bi bi-currency-rupee"></i>{igst.toFixed(2)}
											</td>
											</tr>


										)}


										<tr>
										{/* <td className="text-right" colSpan="4">
											<strong>VAT (20%):</strong>
										</td>
										<td className="text-right">
											<i className="bi bi-currency-rupee"></i>
											{calculateVAT()}
										</td> */}
										</tr>
										<tr>
										<td className="text-right" colSpan="5">
											<strong>Total:</strong>
										</td>
										<td className="text-right">
										<strong>	<i className="bi bi-currency-rupee"></i>
											{total.toFixed(2)}</strong>
										</td>
										</tr>
									</tfoot>
									</table>
								</div>
								</div>
							</div>
							</div>
							<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading">
							<h4 className="panel-title">
								<i className="fa fa-pencil"></i> Add Comments About Your Order
							</h4>
							</div>
							<div className="panel-body">
							

							{/* Comments Section */}
							<textarea
								rows="4"
								className="form-control"
								id="confirm_comment"
								name="comments"
								value={comments}
								onChange={(e) => setComments(e.target.value)}
								placeholder="Add your comments here..."
							></textarea>

							<br />
					{/* Travels Name Input */}
					<div className="form-group col-sm-6">
								<label htmlFor="travels_name">Travels Name:</label>
								<input
								type="text"
								className="form-control"
								id="travels_name"
								name="travelsName"
								value={travelsName}
								onChange={(e) => setTravelsName(e.target.value)}
								placeholder="Enter Travels Name"
								/>
							</div>
							{/* Payment Type Dropdown */}
							<div className="form-group col-sm-6">
								<label htmlFor="payment_type">Payment Type:</label>
								<select
									className="form-control"
									id="payment_type"
									name="paymentType"
									value={paymentType}
									onChange={(e) => setPaymentType(e.target.value)}
									style={{
									appearance: "none", // Hides default select arrow
									WebkitAppearance: "none",
									MozAppearance: "none",
									background: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' width=\'18\' height=\'18\' fill=\'%23666\'><path d=\'M7 10l5 5 5-5H7z\'></path></svg>") no-repeat right 10px center',
									backgroundSize: "18px",
									paddingRight: "30px",
									}}
								>
									<option value="">Select Payment Type</option>
									<option value="bank">Bank</option>
									<option value="gpay">GPay</option>
									<option value="rtgs">RTGS</option>
								</select>
								</div>



							{/* Terms and Conditions Checkbox */}
							<label className="control-label" htmlFor="confirm_agree">
								<input
								type="checkbox"
								className="validate required"
								id="confirm_agree"
								checked={agree}
								onChange={handleCheckboxChange}
								name="confirm_agree"
								/>
								<span> &nbsp;
								I have read and agree to the{" "}
								<Link className="agree" to="/terms">
									<b>Terms & Conditions</b>
								</Link>
								</span>
							</label>

							{/* Confirm Order Button */}
							<div className="buttons">
								<div className="pull-right">
								<button
									className="btn btn-primary"
									id="button-confirm"
									onClick={handleConfirmOrder}
									disabled={loading}
									style={{
									display: "inline-flex",
									alignItems: "center",
									justifyContent: "center",
									minWidth: "120px",
									height: "38px",
									}}
								>
									{loading ? <ClipLoader color="#fff" size={16} /> : "Confirm Order"}
								</button>
								</div>
							</div>
							</div>
						</div>
						</div>
				  </div>
				</div>
			  </div>
			</div>
			{/* <!--Middle Part End --> */}
			
		</div>
	</div>
  </div>
</div>
<Footer />
{/* </>
	  )} */}
</>
  )
}

export default Checkout