import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    pauseOnHover: true,
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch("https://unitedmart.in/customerapp/banner.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch banners");
        }

        const data = await response.json();
        console.log("Fetched banners data:", data);

        if (data.body && Array.isArray(data.body.banner)) {
          setBanners(data.body.banner);
        } else {
          console.error("Unexpected API response structure", data);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);


  if (loading) {
    return <div>""</div>;
  }

  return (
   /*  <div className="banner-slider col-lg-8 col-md-8 col-sm-12 col-xs-12 two-block">
      <Slider {...settings}>
        {banners.map((banner) => (
          <div key={banner.id} className="slide">
            <a >
              <img
                src={banner.image} // Updated field to match API response
                alt={banner.name || "Banner"}
                className="img-responsive"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div> */
    <section class="so-slideshow ">
           <div id="so-slideshow">
              <div class="module slideshow no-margin">
                <div
                  class="yt-content-slider yt-content-slider--arrow1"
                >
                  <div class="yt-content-slide">
                  <div >
                    <Slider {...settings}>
                      {banners.map((banner) => (
                        <div key={banner.id} className="slide">
                          <a >
                            <img
                              src={banner.image} // Updated field to match API response
                              alt={banner.name || "Banner"}
                              className="img-responsive"
                            />
                          </a>
                        </div>
                      ))}
                    </Slider>
                  </div> 

                    {/* <div class="module col-md-4  hidden-sm hidden-xs three-block ">
                      <div class="modcontent clearfix">
                        <div class="htmlcontent-block">
                        <ul className="htmlcontent-home">
                          {visibleBanners.map((banner, index) => (
                            <li key={index}>
                              <div className="banners">
                                <div>
                                  <Link
                                    to={`/products/${banner.subcategory_id}`}
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={banner.subcategory_image || "/path/to/fallback-image.jpg"} // Use subcategory_image here
                                      alt={`banner${index + 1}`}
                                      style={{ width: "100%", height: "auto" }} // Adjust styles as needed
                                    />
                                  </Link>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
  );
};

export default Banner;
