import React,{useState,useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import Loader from '../Components/Loader';

const Aboutus = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      // Simulate a network request or loading process
      const timer = setTimeout(() => setIsLoading(false), 3000);
      return () => clearTimeout(timer);
      }, []);
  
  return (
    <>
    {/* {isLoading ? (
        <Loader />
      ) : (
        <> */}
    <div className="common-home res layout-home1">
      <div id="wrapper" className="wrapper-full banners-effect-7">
        <Header />
        <div class="main-container container">
		<ul class="breadcrumb">
			<li><a href="index.html"><i class="fa fa-home"></i></a></li>
			<li>About Us</li>
		</ul>
		<Container className="company-profile-container">
      <Row>
        <Col>
          <Card className="company-profile-card">
            <Card.Body>
              <Card.Title className="company-profile-title">
                Founded in the year 1994, United Papers
              </Card.Title>
              <Card.Text className="company-profile-text">
                United Papers is one of the top-notch manufacturers, exporters, and suppliers of a variety of paper products. All our products are of premium quality and therefore offer excellent durability. We have several patrons in both domestic and international markets who vouch for our products. They are all extremely happy about the light weight and easy-to-carry properties offered by our paper products. Furthermore, these products have been in great demand in the market for their wear and tear resistant properties as well.
              </Card.Text>
              <Card.Text className="company-profile-text">
                Our sophisticated infrastructure allows us to produce the best quality products while our wide distribution network ensures that we are able to deliver those products well within the given time-frames. Also, the international standards of quality, that we adhere to, assure our customers the best quality products from us. As a result, all our paper products are in complete compliance with the industry norms and standards. Further, the transparent and ethical business policies that we abide by, have helped us to gain the trust of our vast clients.
              </Card.Text>
              <Card.Text className="company-profile-text">
                Baked by the rich industrial experience and expertise, we have gained over the years, we are now highly adept at customizing our products exactly as per the specific requirements of our clients, with respect to size, colour, and texture.
              </Card.Text>
              <Card.Text className="company-profile-text">
                Under the able guidance of our esteemed Director, Mr. Kamalnath J, we have been able to steer our company ahead of most competitors by a fair margin. With his insightful knowledge and inspirational talks, he has been a constant source of motivation to one and all in the organization.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
		
	</div>
    <Footer />
      </div>
    </div>
    {/* </>
	  )} */}
</>
  )
}

export default Aboutus